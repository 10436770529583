import { Component, Input, inject } from "@angular/core";
import type { ModalResult } from "@limblecmms/lim-ui";
import {
   BasicModalHeaderComponent,
   InfoPanelComponent,
   LimbleHtmlDirective,
   LimUiModalRef,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   ModalFooterComponent,
   OutlinedButtonComponent,
   PrimaryButtonComponent,
} from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";

@Component({
   selector: "confirm",
   templateUrl: "./confirm-modal.component.html",
   styleUrls: ["./confirm-modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      InfoPanelComponent,
      LimbleHtmlDirective,
      ModalFooterComponent,
      OutlinedButtonComponent,
      PrimaryButtonComponent,
   ],
})
export class ConfirmModalComponent implements ModalResult<boolean> {
   protected readonly lang = inject(ManageLang).lang;
   @Input({ required: true }) public message: string = "";
   @Input({ required: true }) public title: string = "";
   @Input() public confirmMessage: string = this.lang()?.Yes ?? "Yes";
   @Input() public cancelMessage: string = this.lang()?.No ?? "No";
   @Input() public onSubmit: (() => void | Promise<void>) | undefined;
   public readonly modalRef: LimUiModalRef<ConfirmModalComponent, boolean> =
      inject(LimUiModalRef);

   public close(): void {
      this.modalRef.close(false);
   }

   public async submit(): Promise<void> {
      if (this.onSubmit) {
         await this.onSubmit();
      }
      this.modalRef.close(true);
   }
}

// This is a shared utility for all file related operations.

// Document files are any files that are not images.
export interface DocumentFile {
   fileName?: string | null;
   getURL?: string;
}

export interface PictureFile {
   fileName?: string | null; // We save the file name for tracking purposes. This enables the templates to track these in loops in a performant way.
   getURL?: string;
}

export const imageExts = new Set<string>([
   "png",
   "tif",
   "tiff",
   "jfif",
   "jpeg",
   "jpg",
   "gif",
   "svg",
]);

export function hasImageExtension(filename: string): boolean {
   return imageExts.has(parseFileExtension(filename));
}

export function parseFileExtension(filename: string): string {
   return filename.substring(filename.lastIndexOf(".") + 1).toLowerCase();
}

import { Injectable } from "@angular/core";
import type { Observable } from "rxjs";
import { assetFieldResource } from "src/app/lite/local-db/resources/collection/asset/field/asset-field.resource";
import type { AssetFieldUpdate } from "src/app/lite/local-db/resources/collection/asset/field/asset-field.storage.service.types";
import type { AssetField } from "src/app/lite/local-db/resources/collection/asset/field/asset-field.types";
import { CollectionStorageService } from "src/app/lite/local-db/resources/collection/collection.storage.service";
import type {
   ExtractDisableAutoIncrement,
   ExtractIndexes,
   ExtractPrimaryKey,
   ExtractSchema,
   Insertable,
} from "src/app/lite/local-db/resources/collection/collection.storage.service.types";

@Injectable({ providedIn: "root" })
export class AssetFieldStorageService extends CollectionStorageService<
   ExtractSchema<typeof assetFieldResource>,
   ExtractPrimaryKey<typeof assetFieldResource>,
   ExtractDisableAutoIncrement<typeof assetFieldResource>,
   ExtractIndexes<typeof assetFieldResource>
> {
   public constructor() {
      super(assetFieldResource);
   }

   public getAssetFieldsForLocation$(locationID: number): Observable<Array<AssetField>> {
      return this.read$((transaction) =>
         this.getAllFromIndex$("locationID", transaction, { indexValue: locationID }),
      );
   }

   public getAssetFields$(): Observable<Array<AssetField>> {
      return this.read$((transaction) => this.getAll$(transaction));
   }

   public getAssetField$(fieldID: number): Observable<AssetField | undefined> {
      return this.read$((transaction) => this.get$(fieldID, transaction));
   }

   public async putAssetField(fieldID: InsertableAssetField): Promise<AssetField> {
      return this.write(async (transaction) => this.put(fieldID, transaction));
   }

   public async updateAssetField(
      fieldID: number,
      update: AssetFieldUpdate,
   ): Promise<AssetField | undefined> {
      return this.write(async (transaction) => this.set(fieldID, update, transaction));
   }
}

type InsertableAssetField = Insertable<typeof assetFieldResource>;

import type { PipeTransform } from "@angular/core";
import { Pipe } from "@angular/core";
/*
 * Takes a string and capitalizes the first letter of the first word, and lowercases the rest.
 *    -replacement for angularJS "capitalize" filter (different than the built in titlecase pipe)
 * Takes a input string
 * Usage:
 *    string | capitalize
 * Example:
 *   this.lang().sampleMsg | capitalize
 */
@Pipe({
   name: "capitalize",
   standalone: true,
})
export class CapitalizePipe implements PipeTransform {
   public transform(input: string | null | undefined) {
      if (input === null || input === undefined) {
         return input;
      }
      return capitalize(input);
   }
}

export function capitalize(input: string) {
   const lowercaseInput = input.toLowerCase();
   return lowercaseInput.substring(0, 1).toUpperCase() + lowercaseInput.substring(1);
}

<lim-ui-modal>
   <lim-ui-basic-modal-header [title]="title" (closeModal)="close()" />
   <lim-ui-modal-body>
      <lim-ui-info-panel>
         <span [limbleHtml]="message"></span>
      </lim-ui-info-panel>
   </lim-ui-modal-body>
   <lim-ui-modal-footer>
      <lim-ui-outlined-button (click)="submit()">
         {{ confirmMessage }}
      </lim-ui-outlined-button>
      <lim-ui-primary-button (click)="close()">
         {{ cancelMessage }}
      </lim-ui-primary-button>
   </lim-ui-modal-footer>
</lim-ui-modal>

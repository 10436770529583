import { Injectable, inject } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { filter, map, type Observable } from "rxjs";

export enum OfflineMode {
   ONLINE = "online",
   OFFLINE = "offline",
}

@Injectable({ providedIn: "root" })
export class ModeTransitionService {
   public readonly modeTransition$: Observable<OfflineMode>;
   private readonly router = inject(Router);

   public constructor() {
      this.modeTransition$ = this.router.events.pipe(
         filter((event): event is NavigationStart => event instanceof NavigationStart),
         map(({ url }) => {
            if (url.startsWith("/lite")) {
               return OfflineMode.OFFLINE;
            }
            return OfflineMode.ONLINE;
         }),
      );
   }
}

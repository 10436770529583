import { computed, inject, Injectable } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { ModalService } from "@limblecmms/lim-ui";
import axios from "axios/dist/axios";
import loadImage from "blueimp-load-image";
import * as FilePond from "filepond";
import FilePondPluginFileRename from "filepond-plugin-file-rename";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import { from } from "rxjs";
import type { FileUploaderModel } from "src/app/files/services/file-service.types";
import type {
   CustomFormData,
   ErrorFunction,
   FileOptions,
   LoadFunction,
   ProgressFunction,
   UploadCallback,
   UploadResponse,
} from "src/app/files/services/manageFiles.types";
import { ManageLang } from "src/app/languages/services/manageLang";
import { AlertService } from "src/app/shared/services/alert.service";
import { Flags } from "src/app/shared/services/launch-flags/launch-flags.models";
import { LegacyLaunchFlagsService } from "src/app/shared/services/launch-flags/legacy-launch-flags.service";
import { ParamsService } from "src/app/shared/services/params.service";
import { TaskCompletionSuccessful } from "src/app/tasks/components/taskCompletionSuccessfulModal/taskCompletionSuccessful.modal.component";
import completedGifs from "src/assets/completedGifs.json";
import * as Doka from "src/assets/js/doka";

@Injectable({ providedIn: "root" })
export class ManageFiles {
   public createImportUploader: FilePond.FilePond;
   public createDocUploader: FilePond.FilePond;
   public createImageUploader: FilePond.FilePond;
   public createCameraUploader: FilePond.FilePond;
   public createFileUploader: FilePond.FilePond;
   public createVideoUploader: FilePond.FilePond;
   public createFileVideoUploader: FilePond.FilePond;

   public mostRecentTaskGifUrl: string | undefined;

   private readonly legacyLaunchFlagsService = inject(LegacyLaunchFlagsService);
   private readonly shouldIncludeIs2Extensions = toSignal(
      from(this.legacyLaunchFlagsService.isEnabled(Flags.SUPPORT_IS2_FILES)),
   );

   public extMimeMap = new Map([
      ["pdf", "application/pdf"],
      ["docx", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"],
      ["doc", "application/msword"],
      ["txt", "text/plain"],
      ["ppt", "application/vnd.ms-powerpoint"],
      [
         "pptx",
         "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      ],
      ["xls", "application/vnd.ms-excel"],
      ["xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"],
      ["zip", "application/zip"],
      ["rar", "application/x-rar-compressed"],
      ["eml", "message/rfc822"],
      ["msg", "application/vnd.ms-outlook"],
      ["png", "image/png"],
      ["PNG", "image/png"],
      ["gif", "image/gif"],
      ["GIF", "image/gif"],
      ["jpeg", "image/jpeg"],
      ["jpg", "image/jpeg"],
      ["JPEG", "image/jpeg"],
      ["jfif", "image/jpeg"],
      ["dwg", "image/vnd.dwg"],
      ["dxf", "image/vnd.dxf"],
      ["svg", "image/svg+xml"],
      ["SVG", "image/svg+xml"],
      ["tif", "image/tiff"],
      ["tiff", "image/tif"],
      ["csv", "text/csv"],
      ["rpt", ".rpt"],
      ["dxf", ".dxf"],
      ["stp", ".stp"],
      ["nc", ".nc"],
      ["mcx-7", ".mcx-7"],
      ["sldprt", ".sldprt"],
      ["dwg", ".dwg"],
      ["mcam", ".mcam"],
      ["igs", ".igs"],
      ...(this.shouldIncludeIs2Extensions()
         ? [["is2", "application/octet-stream"] as [string, string]]
         : ([] as [string, string][])),
   ]);

   // Dependency update:  This is mirrored by manageProblem.
   // If you update this list, please update the conditional list on manageProblem.findFirstAvailableImage
   public imageExts = [
      "png",
      "PNG",
      "tif",
      "tiff",
      "jfif",
      "jpeg",
      "JPEG",
      "jpg",
      "JPG",
      "gif",
      "GIF",
      "svg",
      "SVG",
   ];

   public fileExts = [
      "pdf",
      "doc",
      "docx",
      "xls",
      "xlsx",
      "txt",
      "zip",
      "rar",
      "msg",
      "eml",
      "nc",
      "mcx-7",
      "sldprt",
      "dxf",
      "dwg",
      "mcam",
      "igs",
   ];

   public allowedExts = [
      "pdf",
      "png",
      "gif",
      "jpeg",
      "jpg",
      "doc",
      "docx",
      "dwg",
      "dxf",
      "txt",
      "ppt",
      "pptx",
      "xls",
      "xlsx",
      "zip",
      "rar",
      "eml",
      "jfif",
      "svg",
      "msg",
      "mp4",
   ];

   private readonly images = [
      "image/png",
      "image/gif",
      "image/jpeg", // also jfif, jpg
      "image/svg+xml",
      ...(this.shouldIncludeIs2Extensions() ? ["application/octet-stream"] : []),
   ];

   private readonly videos = ["video/mp4"];

   private readonly documents = [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // docx
      "application/msword", // doc
      "text/plain", // txt
      "application/vnd.ms-powerpoint", // ppt
      "application/vnd.openxmlformats-officedocument.presentationml.presentation", // pptx
      "application/vnd.ms-excel", // xls
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // xlsx
      "application/zip",
      "application/vnd.ms-office", // rss
      "application/x-rar-compressed", // rar
      "application/x-zip-compressed", // zip
      "message/rfc822", // eml email message
      "application/vnd.ms-outlook", // msg???
      "text/csv", // comma separated values
      ".rpt",
      ".dxf",
      ".stp",
      ".nc",
      ".mcx-7",
      ".sldprt",
      ".dwg",
      ".mcam",
      ".igs",
      ...(this.shouldIncludeIs2Extensions() ? [".is2"] : []),
   ];

   private readonly importFiles = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", //xlsx
      "application/vnd.ms-excel", //xls
      "text/csv",
   ];

   private readonly axios = axios;
   private readonly alertService = inject(AlertService);
   private readonly manageLang = inject(ManageLang);
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});
   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);
   private readonly defaultFilePondOptions: FilePond.FilePondOptions = {
      allowMultiple: true,
      allowPaste: false,
      fileValidateTypeDetectType: async (source: File, type: string): Promise<string> => {
         return Promise.resolve(this.fileValidator(source, type));
      },
      fileRenameFunction: (fileOptions: FileOptions): string => {
         return this.jfifCleaner(fileOptions);
      },
   };

   public constructor() {
      //plugins need to be registered first
      FilePond.registerPlugin(
         FilePondPluginFileValidateType,
         FilePondPluginImageExifOrientation,
         FilePondPluginFileRename,
      );

      this.createImportUploader = FilePond.create(undefined);
      this.createImportUploader.setOptions({
         ...this.defaultFilePondOptions,
         acceptedFileTypes: this.importFiles,
         maxFiles: 1,
         allowMultiple: false,
         fileRenameFunction: null,
      });

      this.createFileVideoUploader = FilePond.create(undefined);
      this.createFileVideoUploader.setOptions({
         ...this.defaultFilePondOptions,
         acceptedFileTypes: [...this.documents, ...this.images, ...this.videos],
      });

      this.createFileUploader = FilePond.create(undefined);
      this.createFileUploader.setOptions({
         ...this.defaultFilePondOptions,
         acceptedFileTypes: [...this.documents, ...this.images],
      });

      this.createDocUploader = FilePond.create(undefined);
      this.createDocUploader.setOptions({
         ...this.defaultFilePondOptions,
         acceptedFileTypes: this.documents,
      });

      this.createVideoUploader = FilePond.create(undefined);
      this.createVideoUploader.setOptions({
         ...this.defaultFilePondOptions,
         acceptedFileTypes: this.videos,
      });

      this.createImageUploader = FilePond.create(undefined);
      this.createImageUploader.setOptions({
         ...this.defaultFilePondOptions,
         acceptedFileTypes: this.images,
      });

      this.createCameraUploader = FilePond.create(undefined);
      this.createCameraUploader.setOptions({
         ...this.defaultFilePondOptions,
         acceptedFileTypes: this.images,
         captureMethod: "camera",
      });
   }

   public setUploadServerSettings(
      filePondUploader: FilePond.FilePond,
      fileUploaderModel: FileUploaderModel,
      uploaded: UploadCallback,
      uploading?: UploadCallback,
   ) {
      filePondUploader.server = {
         process: (_fieldName, file, _metadata, load, error, progress) => {
            (async () => {
               if (uploading !== undefined) uploading();
               const fileName = this.sanitizeFileName(file.name);
               const formData = await this.prepareFormData(
                  file,
                  fileName,
                  fileUploaderModel,
               );

               if (formData === null) return;
               fileUploaderModel.posturl = this.constructUploadUrl(
                  fileUploaderModel.posturl,
                  fileName,
                  formData,
               );

               if (fileUploaderModel.uploadCall === undefined) {
                  console.error("uploadCall is not defined");
               } else {
                  const uploadRes = await fileUploaderModel.uploadCall(
                     fileUploaderModel.posturl,
                     formData,
                  );

                  if (uploadRes?.data) {
                     this.handleUploadResponse(
                        uploadRes,
                        load,
                        progress,
                        error,
                        fileUploaderModel,
                     );
                  }
               }
               uploaded();
            })().catch((err: unknown) => {
               if (err instanceof Error) {
                  error(err.message);
               } else {
                  error(String(err));
               }
            });
         },
      };
   }

   public createImageViewer(src: string) {
      return (Doka.create as any)({
         className: "viewOnly",
         allowAutoDestroy: true,
         labelButtonCancel: "Close",
         src: src,
      });
   }

   public async createImageEditor(src: string) {
      //run with axios so we can capture the requests offline
      return this.axios({
         url: src,
         method: "GET",
         responseType: "arraybuffer",
      }) // Call axios passing the url of the API as a parameter
         .then((res) => {
            const blob = new Blob([res.data], { type: "image/" });
            return (Doka.create as any)({
               // Doka.js options here ...
               utils: ["markup", "crop"],
               util: "markup",
               markupAllowCustomColor: false,
               markupColorOptions: [
                  ["Green", "#6df102"],
                  ["Red", "#f44336"],
                  ["White", "#fff", "#f6f6f6"],
                  ["Black", "#000", "#333"],
               ],
               labelButtonCancel: "Close",
               markupLineStyleOptions: [],
               markupUtil: "draw",
               markupColor: "#6df102",
               allowAutoDestroy: true,
               src: blob,
            });
         });
   }

   //checks if this file string has a valid image ext that Limble allows
   public checkImageExt(filename: string) {
      return this.imageExts.includes(this.getFileExt(filename));
   }

   //checks if this file string has a valid document type
   public checkDocumentExts(filename: string) {
      return this.fileExts.includes(this.getFileExt(filename));
   }

   public showCompletedTaskGif(hideIn: number | undefined) {
      const gifs = completedGifs;
      const gif = gifs[Math.floor(Math.random() * gifs.length)];

      //sets the description
      const instance = this.modalService.open(TaskCompletionSuccessful);
      const imageUrl = `assets/img/completeTaskGifs/${gif.name}`;
      this.mostRecentTaskGifUrl = imageUrl;

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: gif.description,
            gifName: gif.name,
            imageUrl: imageUrl,
         },
      };

      //we auto hide after a period of time to motivate them to complete more tasks.
      const closeModalTimeout = setTimeout(() => {
         instance.close();
      }, hideIn);

      // If the user closes the modal themselves, we need to clear the interval that closes a modal.
      instance.result.then(() => {
         clearTimeout(closeModalTimeout);
      });
   }

   public sortByVisibleFileName(fileList: { fileName: string }[]) {
      return fileList.sort((fileA, fileB) => {
         const fileNameA = this.getWOFileExt(fileA.fileName.slice(5).toLowerCase());
         const fileNameB = this.getWOFileExt(fileB.fileName.slice(5).toLowerCase());

         const reg = /^[a-z]+[0-9]+$/;
         //smart sort letters followed by numbers
         if (reg.test(fileNameA) && reg.test(fileNameB)) {
            return this.sortAlphaNumeric(fileNameA, fileNameB);
         }

         return fileNameA < fileNameB ? -1 : 1;
      });
   }

   public getFileExtViaRegex(fileName: string): string {
      const regex = /(?:\.([^.]+))?$/;
      const regexReturn = regex.exec(fileName);
      if (!regexReturn || regexReturn.length <= 1 || !regexReturn[1]) {
         return "";
      }
      return regexReturn[1]?.toLowerCase();
   }

   private getFileExt(filename: string) {
      return filename.substring(filename.lastIndexOf(".") + 1).toLowerCase();
   }

   private getWOFileExt(filename: string) {
      return filename.substring(0, filename.lastIndexOf("."));
   }

   /**
    * Convert jfif to jpeg for Doka
    */
   private jfifCleaner(fileOptions: FileOptions) {
      if (fileOptions.extension === ".jfif") {
         fileOptions.extension = ".jpeg";
         fileOptions.name = fileOptions.name.replace(".jfif", ".jpeg");
      }
      return fileOptions.name;
   }

   private fileValidator(source: File, type: string): string {
      const fileExt = this.getFileExt(source.name);
      let mimeType = type === "" ? this.extMimeMap.get(fileExt) : type;
      mimeType = mimeType ?? "";

      if (mimeType === "") {
         this.alertService.addAlert(this.lang().FileTypeNotSupported, "danger", 2000);
      }

      return mimeType;
   }

   private sanitizeFileName(fileName: string) {
      return fileName.replace(/\s+/g, "-");
   }

   private async prepareFormData(
      file: FilePond.ActualFileObject,
      fileName: string,
      uploadObject: FileUploaderModel,
   ): Promise<CustomFormData | null> {
      let formData = new FormData();
      if (this.checkImageExt(fileName)) {
         formData = await this.compressImage(file);
      } else {
         formData.append("myfile", file, fileName);
         // 50MB limit
         if (file.size >= 52428800) {
            if (uploadObject.errorHandler !== undefined) {
               uploadObject.errorHandler(this.lang().fileTooLargeError);
            }
            uploadObject.uploadComplete({ failed: true });
            return null;
         }
      }
      return formData;
   }

   private constructUploadUrl(
      baseUrl: string,
      fileName: string,
      formData: CustomFormData,
   ) {
      let url = `${baseUrl}&fileName=${fileName}`;
      if (formData.preLastModified) {
         url += `&lastModified=${Math.round(formData.preLastModified / 1000)}`;
      }
      return url;
   }

   private handleUploadResponse(
      response: UploadResponse,
      load: LoadFunction,
      progress: ProgressFunction,
      error: ErrorFunction,
      uploadObject: FileUploaderModel,
   ) {
      if (
         response.data.success ||
         response.data.errorMsg == 0 ||
         (response.data.success == false && response.data.error)
      ) {
         uploadObject.uploadComplete(response.data);
         load(response.data.fileName);
         progress(true, 1024, 1024);
         uploadObject.uploading = false;
      } else {
         this.handleError(error, uploadObject);
      }
   }

   private handleError(errorFunction: ErrorFunction, uploadObject: FileUploaderModel) {
      if (uploadObject.errorHandler !== undefined) {
         uploadObject.errorHandler(this.lang().FileUploadError1);
      }
      errorFunction("Error uploading file");
      uploadObject.uploadComplete({ failed: true });
   }

   private async compressImage(file: FilePond.ActualFileObject): Promise<FormData> {
      return new Promise((resolve) => {
         const formData: CustomFormData = new FormData();

         const lastModified = file.lastModified;

         loadImage.parseMetaData(file, () => {
            loadImage(
               file,
               (canvas) => {
                  if (!(canvas instanceof HTMLCanvasElement)) {
                     throw new Error("Canvas is not an instance of HTMLCanvasElement");
                  }

                  // treat most file types as jpeg by default
                  let fileType = "image/jpeg";

                  if (file.type === "image/png" || file.type === "image/gif") {
                     fileType = file.type;
                  } else {
                     // We want to preserve transparency for PNG and GIF files so we only call this for other file types
                     this.addBackgroundColor(canvas, "white");
                  }

                  //here's the base64 data result
                  const base64data = canvas.toDataURL(fileType);
                  //now we take this and load it into a blob
                  const blob = this.dataURItoBlob(base64data);

                  const outputExtension = blob.type.replace("image/", "");
                  const baseFileName = file.name
                     .replace(/\d\d\d\d-/, "")
                     .substring(0, file.name.lastIndexOf("."));

                  formData.append("myfile", blob, `${baseFileName}.${outputExtension}`);
                  formData.preLastModified = lastModified;
                  resolve(formData);
               },
               {
                  //should be set to canvas : true to activate auto fix orientation
                  canvas: true,
                  orientation: true,
                  maxWidth: 1200,
               },
            );
         });
      });
   }

   private addBackgroundColor(canvas: HTMLCanvasElement, color: string) {
      const ctx = canvas.getContext("2d");
      if (ctx === null) return;

      const originalCompositeOperation = ctx.globalCompositeOperation;
      ctx.globalCompositeOperation = "destination-over";
      ctx.fillStyle = color;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.globalCompositeOperation = originalCompositeOperation;
   }

   private dataURItoBlob(dataURI: string) {
      // convert base64 to raw binary data held in a string
      // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
      const byteString = atob(dataURI.split(",")[1]);

      // separate out the mime component
      const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

      // write the bytes of the string to an ArrayBuffer
      const arrBuf = new ArrayBuffer(byteString.length);
      const intArr = new Uint8Array(arrBuf);
      for (let byte = 0; byte < byteString.length; byte++) {
         intArr[byte] = byteString.charCodeAt(byte);
      }
      //New Code
      return new Blob([arrBuf], { type: mimeString });
   }

   private sortAlphaNumeric(fileNameA: string, fileNameB: string) {
      const digitsA = /\d+/.exec(fileNameA);
      const digitsB = /\d+/.exec(fileNameB);

      if (!digitsA || !digitsB) {
         return fileNameA.localeCompare(fileNameB);
      }

      const stringA = fileNameA.slice(0, digitsA.index);
      const stringB = fileNameB.slice(0, digitsB.index);

      const numberA = Number(fileNameA.slice(digitsA.index));
      const numberB = Number(fileNameB.slice(digitsB.index));

      if (stringA == stringB) {
         return numberA < numberB ? -1 : 1;
      }

      return stringA < stringB ? -1 : 1;
   }
}

import { NgClass } from "@angular/common";
import {
   inject,
   Component,
   output,
   input,
   signal,
   type OnDestroy,
   type OnInit,
} from "@angular/core";
import { IconComponent, TooltipDirective, isMobile } from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import type { Timer as JsTimer } from "src/app/shared/utils/app.util";
import { Timer } from "src/app/tasks/timer/timer";
import type { TimerDuration } from "src/app/tasks/timer/timer-duration";
import { TimerFormatPipe } from "src/app/tasks/timer/timer-format.pipe";

/**
 * A component that displays a timer which can be started, paused, and stopped.
 * The elapsed time is displayed in hours, minutes, and seconds.
 *
 * The timer will still be accurate even if the app process is paused: It uses
 * stored dates to calculate elapsed time, rather than tracking clock ticks one
 * at a time.
 */
@Component({
   selector: "task-timer",
   templateUrl: "./task-timer.component.html",
   styleUrls: ["./task-timer.component.scss"],
   imports: [IconComponent, NgClass, TooltipDirective, TimerFormatPipe],
})
export class TaskTimerComponent implements OnInit, OnDestroy {
   /** If provided, displays a hint on the timer. This value is in seconds. */
   public estimatedTime = input<number>(0);

   public readonly taskID = input.required<number>();

   /**
    * Each time the user clicks the "stop" button, emits an object representing the
    * current time displayed on the timer.
    */
   public readonly stopped = output<TimerDuration>();
   protected readonly lang = inject(ManageLang).lang;
   protected readonly isMobile = isMobile();
   protected time = signal(0);
   private timer!: Timer;
   private interval!: JsTimer;

   public ngOnInit(): void {
      this.timer = Timer.start(this.taskID());
      this.interval = setInterval(() => {
         this.time.set(this.timer.elapsedTime());
      }, 1000);
   }

   public getElapsedTime(): number {
      this.time.set(this.timer.elapsedTime());
      return this.time();
   }

   public getTimerDuration(): TimerDuration {
      const time = this.timer.elapsedTime();
      return {
         total: time,
         hours: Math.floor(time / 3600),
         minutes: Math.floor((time % 3600) / 60),
         seconds: time % 60,
      };
   }

   public addTime(seconds: number) {
      const time = this.timer.elapsedTime();
      this.timer.setElapsedTime(time + seconds);
   }

   public getRunningState(): boolean {
      return this.timer.isRunning();
   }

   public pause({ emit }: { emit: boolean }): void {
      this.timer.stop();
      if (emit) {
         this.stopped.emit(this.getTimerDuration());
      }
   }

   public resume(): void {
      this.timer.start();
   }

   public reset(): void {
      this.timer.reset();
      this.time.set(0);
      this.timer.start();
   }

   public ngOnDestroy(): void {
      clearInterval(this.interval);
   }
}

import { Injectable, inject } from "@angular/core";
import type { DeviceInfo } from "median-js-bridge/dist/commands/general";
import semver from "semver";
import { MobileNativeDeviceInfoService } from "src/app/shared/services/mobile-native-device-info/mobile-native-device-info.service";

@Injectable({ providedIn: "root" })
export class AndroidFeatureAvailabilityService {
   private readonly deviceInfo = inject(MobileNativeDeviceInfoService).deviceInfo;

   public async hasFileUploadMenu(): Promise<boolean> {
      return this.isNativeAndroidAnd((deviceInfo) =>
         semver.gte(deviceInfo.appVersion, "3.0.0"),
      );
   }

   private async isNativeAndroidAnd(
      check: (deviceInfo: DeviceInfo) => boolean,
   ): Promise<boolean> {
      const deviceInfo = await this.getNativeAndroidInfo();
      return deviceInfo === undefined ? false : check(deviceInfo);
   }

   private async getNativeAndroidInfo(): Promise<DeviceInfo | undefined> {
      const deviceInfo = await this.deviceInfo;
      return deviceInfo?.platform === "android" ? deviceInfo : undefined;
   }
}

import { assetResource } from "src/app/lite/local-db/resources/collection/asset/asset.resource";
import type { Asset } from "src/app/lite/local-db/resources/collection/asset/asset.types";
import { paramsWithDependencies } from "src/app/lite/local-db/resources/collection/collection.resource.utils";
import type { CollectionRequest } from "src/app/lite/local-db/resources/collection/collection.resource.utils.types";

export const assetFieldValueInitializer: CollectionRequest = {
   url: "assets/fields/values",
   params: paramsWithDependencies([assetResource], (assets: Array<Asset>) => ({
      assetIDs: assets.map((asset) => asset.assetID),
      valueLastEdited: 0,
   })),
};

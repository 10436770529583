import type { AxiosError } from "axios/dist/axios";
import axios from "axios/dist/axios";
import type { Observable } from "rxjs";
import { catchError, from, map, tap } from "rxjs";
import { fileLimiter } from "src/app/lite/local-db/resources/collection/task/file/file-limiter";
import type {
   TaskFile,
   TaskFileData,
} from "src/app/lite/local-db/resources/collection/task/file/file.types";
import type {
   BuildTaskFileParams,
   FormatFileDownloadUrlParams,
} from "src/app/lite/local-db/resources/collection/task/file/file.utils.params";
import type { FilePayload } from "src/app/lite/local-db/resources/collection/task/file/file.utils.types";

export function buildFileDownloadUrl({
   customerID,
   fileDownloadPathPrefix,
   fileName,
}: FormatFileDownloadUrlParams): string {
   return `viewFile.php?f=upload-${customerID}/${fileDownloadPathPrefix}/${fileName}`;
}

export function createFileFromFormData(formData: FormData): File | undefined {
   /** tsc does not recognize tsconfig lib: [DOM.Iterable] for an unknown reason. */
   const entriesIterator = (
      formData as any as { entries: () => IterableIterator<[string, FormDataEntryValue]> }
   ).entries();
   const values = [...entriesIterator].map(([, value]) => value);
   return values.find((value): value is File => value instanceof File);
}

export function downloadAndFormatTaskFile({
   customerID,
   fileName,
   fileDownloadPathPrefix,
   parentType,
   parentID,
}: BuildTaskFileParams): Observable<TaskFileData> {
   return downloadFile({
      customerID,
      fileDownloadPathPrefix,
      fileName,
   }).pipe(
      map((filePayload) => ({
         parentType,
         parentID,
         fileName,
         arrayBuffer: filePayload.arrayBuffer,
         mimeType: filePayload.mimeType,
      })),
   );
}

export function createBlob(arrayBuffer: ArrayBuffer, mimeType: string): Blob {
   return new Blob([arrayBuffer], { type: mimeType });
}

export function createBlobURL(file: TaskFile | undefined): string {
   if (file === undefined) return "";
   return URL.createObjectURL(createBlob(file.arrayBuffer, file.mimeType));
}

function downloadFile(params: FormatFileDownloadUrlParams): Observable<FilePayload> {
   return from(
      axios.get(buildFileDownloadUrl(params), {
         responseType: "arraybuffer",
      }),
   ).pipe(
      tap((response) => {
         fileLimiter.increaseDataUsage(response.data.byteLength);
      }),
      map((response) => ({
         arrayBuffer: response.data,
         mimeType: response.headers["content-type"],
      })),
      catchError((error) => handleFileDownloadError(error)),
   );
}

function handleFileDownloadError(error: unknown): Observable<never> {
   if (
      axios.isAxiosError(error) &&
      (error as unknown as AxiosError).response?.status === 403
   ) {
      console.error(
         "Unable to retrieve file from",
         (error as unknown as AxiosError).config.url,
      );
   }
   throw error;
}

import { NgClass } from "@angular/common";
import type { OnChanges, OnInit } from "@angular/core";
import {
   inject,
   ChangeDetectorRef,
   Component,
   EventEmitter,
   Input,
   Output,
   computed,
   forwardRef,
} from "@angular/core";
import { NG_VALUE_ACCESSOR, type ControlValueAccessor } from "@angular/forms";
import {
   DropdownClearFilterItemComponent,
   DropdownComponent,
   DropdownDividerComponent,
   DropdownTextItemComponent,
   MinimalIconButtonComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { noop } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageUser } from "src/app/users/services/manageUser";

type timeData = {
   hour: string;
   minute: string;
   ampm: string;
} | null;
@Component({
   selector: "time-of-day-picker",
   templateUrl: "./timeOfDayPicker.element.component.html",
   styleUrls: ["./timeOfDayPicker.element.component.scss"],
   imports: [
      DropdownComponent,
      NgClass,
      MinimalIconButtonComponent,
      TooltipDirective,
      DropdownTextItemComponent,
      DropdownDividerComponent,
      DropdownClearFilterItemComponent,
   ],
   providers: [
      {
         provide: NG_VALUE_ACCESSOR,
         multi: true,
         useExisting: forwardRef(() => TimeOfDayPicker),
      },
   ],
})
export class TimeOfDayPicker implements ControlValueAccessor, OnInit, OnChanges {
   @Input() public hour;
   @Input() public minute;
   @Input() public tod;
   @Input() public ampm;

   /** Whether or not this component will be opaque when no time has been selected. */
   @Input() public optional: boolean = false;
   @Input() public timeOfDayNoMargin: boolean = false;
   @Output() public readonly hourChange = new EventEmitter();
   @Output() public readonly minuteChange = new EventEmitter();
   @Output() public readonly ampmChange = new EventEmitter();
   @Output() public readonly todChange = new EventEmitter();
   public timeOfDay;
   public random;
   public times;

   private onChangeCallback: (data: timeData) => void = noop;
   protected onTouchedCallback: () => void = noop;

   private readonly ref = inject(ChangeDetectorRef);
   private readonly manageUser = inject(ManageUser);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnChanges(changes) {
      // causes time of day display to turn black instead of grey
      if (changes.tod?.currentValue) {
         this.ref.detectChanges();
      }
   }

   public ngOnInit() {
      if (this.tod) {
         this.timeOfDay = this.tod;
      }

      this.random = Math.floor(Math.random() * 1000);

      //array of potential times the user can pick from
      // Displays 24-hr or 12-hr time, depending on the user's configurations
      if (this.manageUser.getCurrentUser().userInfo.customerClockFormat == "24-hr") {
         this.times = [
            { name: "00:00", hour: "00", minute: "00", ampm: "" },
            { name: "00:15", hour: "00", minute: "15", ampm: "" },
            { name: "00:30", hour: "00", minute: "30", ampm: "" },
            { name: "00:45", hour: "00", minute: "45", ampm: "" },

            { name: "01:00", hour: "01", minute: "00", ampm: "" },
            { name: "01:15", hour: "01", minute: "15", ampm: "" },
            { name: "01:30", hour: "01", minute: "30", ampm: "" },
            { name: "01:45", hour: "01", minute: "45", ampm: "" },

            { name: "02:00", hour: "02", minute: "00", ampm: "" },
            { name: "02:15", hour: "02", minute: "15", ampm: "" },
            { name: "02:30", hour: "02", minute: "30", ampm: "" },
            { name: "02:45", hour: "02", minute: "45", ampm: "" },

            { name: "03:00", hour: "03", minute: "00", ampm: "" },
            { name: "03:15", hour: "03", minute: "15", ampm: "" },
            { name: "03:30", hour: "03", minute: "30", ampm: "" },
            { name: "03:45", hour: "03", minute: "45", ampm: "" },

            { name: "04:00", hour: "04", minute: "00", ampm: "" },
            { name: "04:15", hour: "04", minute: "15", ampm: "" },
            { name: "04:30", hour: "04", minute: "30", ampm: "" },
            { name: "04:45", hour: "04", minute: "45", ampm: "" },

            { name: "05:00", hour: "05", minute: "00", ampm: "" },
            { name: "05:15", hour: "05", minute: "15", ampm: "" },
            { name: "05:30", hour: "05", minute: "30", ampm: "" },
            { name: "05:45", hour: "05", minute: "45", ampm: "" },

            { name: "06:00", hour: "06", minute: "00", ampm: "" },
            { name: "06:15", hour: "06", minute: "15", ampm: "" },
            { name: "06:30", hour: "06", minute: "30", ampm: "" },
            { name: "06:45", hour: "06", minute: "45", ampm: "" },

            { name: "07:00", hour: "07", minute: "00", ampm: "" },
            { name: "07:15", hour: "07", minute: "15", ampm: "" },
            { name: "07:30", hour: "07", minute: "30", ampm: "" },
            { name: "07:45", hour: "07", minute: "45", ampm: "" },

            { name: "08:00", hour: "08", minute: "00", ampm: "" },
            { name: "08:15", hour: "08", minute: "15", ampm: "" },
            { name: "08:30", hour: "08", minute: "30", ampm: "" },
            { name: "08:45", hour: "08", minute: "45", ampm: "" },

            { name: "09:00", hour: "09", minute: "00", ampm: "" },
            { name: "09:15", hour: "09", minute: "15", ampm: "" },
            { name: "09:30", hour: "09", minute: "30", ampm: "" },
            { name: "09:45", hour: "09", minute: "45", ampm: "" },

            { name: "10:00", hour: "10", minute: "00", ampm: "" },
            { name: "10:15", hour: "10", minute: "15", ampm: "" },
            { name: "10:30", hour: "10", minute: "30", ampm: "" },
            { name: "10:45", hour: "10", minute: "45", ampm: "" },

            { name: "11:00", hour: "11", minute: "00", ampm: "" },
            { name: "11:15", hour: "11", minute: "15", ampm: "" },
            { name: "11:30", hour: "11", minute: "30", ampm: "" },
            { name: "11:45", hour: "11", minute: "45", ampm: "" },

            { name: "12:00", hour: "12", minute: "00", ampm: "" },
            { name: "12:15", hour: "12", minute: "15", ampm: "" },
            { name: "12:30", hour: "12", minute: "30", ampm: "" },
            { name: "12:45", hour: "12", minute: "45", ampm: "" },

            { name: "13:00", hour: "13", minute: "00", ampm: "" },
            { name: "13:15", hour: "13", minute: "15", ampm: "" },
            { name: "13:30", hour: "13", minute: "30", ampm: "" },
            { name: "13:45", hour: "13", minute: "45", ampm: "" },

            { name: "14:00", hour: "14", minute: "00", ampm: "" },
            { name: "14:15", hour: "14", minute: "15", ampm: "" },
            { name: "14:30", hour: "14", minute: "30", ampm: "" },
            { name: "14:45", hour: "14", minute: "45", ampm: "" },

            { name: "15:00", hour: "15", minute: "00", ampm: "" },
            { name: "15:15", hour: "15", minute: "15", ampm: "" },
            { name: "15:30", hour: "15", minute: "30", ampm: "" },
            { name: "15:45", hour: "15", minute: "45", ampm: "" },

            { name: "16:00", hour: "16", minute: "00", ampm: "" },
            { name: "16:15", hour: "16", minute: "15", ampm: "" },
            { name: "16:30", hour: "16", minute: "30", ampm: "" },
            { name: "16:45", hour: "16", minute: "45", ampm: "" },

            { name: "17:00 ", hour: "17", minute: "00", ampm: "" },
            { name: "17:15 ", hour: "17", minute: "15", ampm: "" },
            { name: "17:30 ", hour: "17", minute: "30", ampm: "" },
            { name: "17:45 ", hour: "17", minute: "45", ampm: "" },

            { name: "18:00 ", hour: "18", minute: "00", ampm: "" },
            { name: "18:15 ", hour: "18", minute: "15", ampm: "" },
            { name: "18:30 ", hour: "18", minute: "30", ampm: "" },
            { name: "18:45 ", hour: "18", minute: "45", ampm: "" },

            { name: "19:00 ", hour: "19", minute: "00", ampm: "" },
            { name: "19:15 ", hour: "19", minute: "15", ampm: "" },
            { name: "19:30 ", hour: "19", minute: "30", ampm: "" },
            { name: "19:45 ", hour: "19", minute: "45", ampm: "" },

            { name: "20:00 ", hour: "20", minute: "00", ampm: "" },
            { name: "20:15 ", hour: "20", minute: "15", ampm: "" },
            { name: "20:30 ", hour: "20", minute: "30", ampm: "" },
            { name: "20:45 ", hour: "20", minute: "45", ampm: "" },

            { name: "21:00 ", hour: "21", minute: "00", ampm: "" },
            { name: "21:15 ", hour: "21", minute: "15", ampm: "" },
            { name: "21:30 ", hour: "21", minute: "30", ampm: "" },
            { name: "21:45 ", hour: "21", minute: "45", ampm: "" },

            { name: "22:00 ", hour: "22", minute: "00", ampm: "" },
            { name: "22:15 ", hour: "22", minute: "15", ampm: "" },
            { name: "22:30 ", hour: "22", minute: "30", ampm: "" },
            { name: "22:45 ", hour: "22", minute: "45", ampm: "" },

            { name: "23:00 ", hour: "23", minute: "00", ampm: "" },
            { name: "23:15 ", hour: "23", minute: "15", ampm: "" },
            { name: "23:30 ", hour: "23", minute: "30", ampm: "" },
            { name: "23:45 ", hour: "23", minute: "45", ampm: "" },
         ];
      } else {
         this.times = [
            { name: "12:00 AM", hour: "12", minute: "00", ampm: "AM" },
            { name: "12:15 AM", hour: "12", minute: "15", ampm: "AM" },
            { name: "12:30 AM", hour: "12", minute: "30", ampm: "AM" },
            { name: "12:45 AM", hour: "12", minute: "45", ampm: "AM" },

            { name: "1:00 AM", hour: "01", minute: "00", ampm: "AM" },
            { name: "1:15 AM", hour: "01", minute: "15", ampm: "AM" },
            { name: "1:30 AM", hour: "01", minute: "30", ampm: "AM" },
            { name: "1:45 AM", hour: "01", minute: "45", ampm: "AM" },

            { name: "2:00 AM", hour: "02", minute: "00", ampm: "AM" },
            { name: "2:15 AM", hour: "02", minute: "15", ampm: "AM" },
            { name: "2:30 AM", hour: "02", minute: "30", ampm: "AM" },
            { name: "2:45 AM", hour: "02", minute: "45", ampm: "AM" },

            { name: "3:00 AM", hour: "03", minute: "00", ampm: "AM" },
            { name: "3:15 AM", hour: "03", minute: "15", ampm: "AM" },
            { name: "3:30 AM", hour: "03", minute: "30", ampm: "AM" },
            { name: "3:45 AM", hour: "03", minute: "45", ampm: "AM" },

            { name: "4:00 AM", hour: "04", minute: "00", ampm: "AM" },
            { name: "4:15 AM", hour: "04", minute: "15", ampm: "AM" },
            { name: "4:30 AM", hour: "04", minute: "30", ampm: "AM" },
            { name: "4:45 AM", hour: "04", minute: "45", ampm: "AM" },

            { name: "5:00 AM", hour: "05", minute: "00", ampm: "AM" },
            { name: "5:15 AM", hour: "05", minute: "15", ampm: "AM" },
            { name: "5:30 AM", hour: "05", minute: "30", ampm: "AM" },
            { name: "5:45 AM", hour: "05", minute: "45", ampm: "AM" },

            { name: "6:00 AM", hour: "06", minute: "00", ampm: "AM" },
            { name: "6:15 AM", hour: "06", minute: "15", ampm: "AM" },
            { name: "6:30 AM", hour: "06", minute: "30", ampm: "AM" },
            { name: "6:45 AM", hour: "06", minute: "45", ampm: "AM" },

            { name: "7:00 AM", hour: "07", minute: "00", ampm: "AM" },
            { name: "7:15 AM", hour: "07", minute: "15", ampm: "AM" },
            { name: "7:30 AM", hour: "07", minute: "30", ampm: "AM" },
            { name: "7:45 AM", hour: "07", minute: "45", ampm: "AM" },

            { name: "8:00 AM", hour: "08", minute: "00", ampm: "AM" },
            { name: "8:15 AM", hour: "08", minute: "15", ampm: "AM" },
            { name: "8:30 AM", hour: "08", minute: "30", ampm: "AM" },
            { name: "8:45 AM", hour: "08", minute: "45", ampm: "AM" },

            { name: "9:00 AM", hour: "09", minute: "00", ampm: "AM" },
            { name: "9:15 AM", hour: "09", minute: "15", ampm: "AM" },
            { name: "9:30 AM", hour: "09", minute: "30", ampm: "AM" },
            { name: "9:45 AM", hour: "09", minute: "45", ampm: "AM" },

            { name: "10:00 AM", hour: "10", minute: "00", ampm: "AM" },
            { name: "10:15 AM", hour: "10", minute: "15", ampm: "AM" },
            { name: "10:30 AM", hour: "10", minute: "30", ampm: "AM" },
            { name: "10:45 AM", hour: "10", minute: "45", ampm: "AM" },

            { name: "11:00 AM", hour: "11", minute: "00", ampm: "AM" },
            { name: "11:15 AM", hour: "11", minute: "15", ampm: "AM" },
            { name: "11:30 AM", hour: "11", minute: "30", ampm: "AM" },
            { name: "11:45 AM", hour: "11", minute: "45", ampm: "AM" },

            { name: "12:00 PM", hour: "12", minute: "00", ampm: "PM" },
            { name: "12:15 PM", hour: "12", minute: "15", ampm: "PM" },
            { name: "12:30 PM", hour: "12", minute: "30", ampm: "PM" },
            { name: "12:45 PM", hour: "12", minute: "45", ampm: "PM" },

            { name: "1:00 PM", hour: "01", minute: "00", ampm: "PM" },
            { name: "1:15 PM", hour: "01", minute: "15", ampm: "PM" },
            { name: "1:30 PM", hour: "01", minute: "30", ampm: "PM" },
            { name: "1:45 PM", hour: "01", minute: "45", ampm: "PM" },

            { name: "2:00 PM", hour: "02", minute: "00", ampm: "PM" },
            { name: "2:15 PM", hour: "02", minute: "15", ampm: "PM" },
            { name: "2:30 PM", hour: "02", minute: "30", ampm: "PM" },
            { name: "2:45 PM", hour: "02", minute: "45", ampm: "PM" },

            { name: "3:00 PM", hour: "03", minute: "00", ampm: "PM" },
            { name: "3:15 PM", hour: "03", minute: "15", ampm: "PM" },
            { name: "3:30 PM", hour: "03", minute: "30", ampm: "PM" },
            { name: "3:45 PM", hour: "03", minute: "45", ampm: "PM" },

            { name: "4:00 PM", hour: "04", minute: "00", ampm: "PM" },
            { name: "4:15 PM", hour: "04", minute: "15", ampm: "PM" },
            { name: "4:30 PM", hour: "04", minute: "30", ampm: "PM" },
            { name: "4:45 PM", hour: "04", minute: "45", ampm: "PM" },

            { name: "5:00 PM", hour: "05", minute: "00", ampm: "PM" },
            { name: "5:15 PM", hour: "05", minute: "15", ampm: "PM" },
            { name: "5:30 PM", hour: "05", minute: "30", ampm: "PM" },
            { name: "5:45 PM", hour: "05", minute: "45", ampm: "PM" },

            { name: "6:00 PM", hour: "06", minute: "00", ampm: "PM" },
            { name: "6:15 PM", hour: "06", minute: "15", ampm: "PM" },
            { name: "6:30 PM", hour: "06", minute: "30", ampm: "PM" },
            { name: "6:45 PM", hour: "06", minute: "45", ampm: "PM" },

            { name: "7:00 PM", hour: "07", minute: "00", ampm: "PM" },
            { name: "7:15 PM", hour: "07", minute: "15", ampm: "PM" },
            { name: "7:30 PM", hour: "07", minute: "30", ampm: "PM" },
            { name: "7:45 PM", hour: "07", minute: "45", ampm: "PM" },

            { name: "8:00 PM", hour: "08", minute: "00", ampm: "PM" },
            { name: "8:15 PM", hour: "08", minute: "15", ampm: "PM" },
            { name: "8:30 PM", hour: "08", minute: "30", ampm: "PM" },
            { name: "8:45 PM", hour: "08", minute: "45", ampm: "PM" },

            { name: "9:00 PM", hour: "09", minute: "00", ampm: "PM" },
            { name: "9:15 PM", hour: "09", minute: "15", ampm: "PM" },
            { name: "9:30 PM", hour: "09", minute: "30", ampm: "PM" },
            { name: "9:45 PM", hour: "09", minute: "45", ampm: "PM" },

            { name: "10:00 PM", hour: "10", minute: "00", ampm: "PM" },
            { name: "10:15 PM", hour: "10", minute: "15", ampm: "PM" },
            { name: "10:30 PM", hour: "10", minute: "30", ampm: "PM" },
            { name: "10:45 PM", hour: "10", minute: "45", ampm: "PM" },

            { name: "11:00 PM", hour: "11", minute: "00", ampm: "PM" },
            { name: "11:15 PM", hour: "11", minute: "15", ampm: "PM" },
            { name: "11:30 PM", hour: "11", minute: "30", ampm: "PM" },
            { name: "11:45 PM", hour: "11", minute: "45", ampm: "PM" },
         ];
      }
   }

   public writeValue(data: timeData): void {
      if (data === null) {
         this.resetAnyTime();
         return;
      }
      this.hour = data?.hour ?? "12";
      this.minute = data?.minute ?? "00";
      this.ampm = data?.ampm ?? "AM";
   }

   public registerOnChange(callback: (data: timeData) => void): void {
      this.onChangeCallback = callback;
   }

   public registerOnTouched(callback: () => void): void {
      this.onTouchedCallback = callback;
   }

   setHour = (hour) => {
      this.hour = hour;
      this.timeOfDay = true;
      this.hourChange.emit(this.hour);
      this.todChange.emit(this.timeOfDay);
   };

   setMinute = (minute) => {
      this.minute = minute;
      this.timeOfDay = true;
      this.minuteChange.emit(this.minute);
      this.todChange.emit(this.timeOfDay);
   };

   setAMPM = (ampm) => {
      this.ampm = ampm;
      this.timeOfDay = true;
      this.ampmChange.emit(this.ampm);
      this.todChange.emit(this.timeOfDay);
   };

   resetAnyTime = () => {
      this.timeOfDay = false;
      if (this.manageUser.getCurrentUser().userInfo.customerClockFormat == "24-hr") {
         this.hour = "00";
         this.minute = "00";
         this.ampm = "";
      } else {
         this.hour = "12";
         this.minute = "00";
         this.ampm = "AM";
      }
      this.todChange.emit(this.timeOfDay);
      this.hourChange.emit(this.hour);
      this.minuteChange.emit(this.minute);
      this.ampmChange.emit(this.ampm);

      this.onChangeCallback(null);
   };

   protected setTimeData(time: any) {
      this.setHour(time.hour);
      this.setMinute(time.minute);
      this.setAMPM(time.ampm);

      this.onChangeCallback({
         hour: this.hour,
         minute: this.minute,
         ampm: this.ampm,
      });
   }
}

import { Injectable } from "@angular/core";
import { combineLatestWith, map, type Observable } from "rxjs";
import { CollectionStorageService } from "src/app/lite/local-db/resources/collection/collection.storage.service";
import type {
   ExtractDisableAutoIncrement,
   ExtractIndexes,
   ExtractPrimaryKey,
   ExtractSchema,
   Insertable,
} from "src/app/lite/local-db/resources/collection/collection.storage.service.types";
import { taskFileResource } from "src/app/lite/local-db/resources/collection/task/file/file.resource";
import type { FileUpdate } from "src/app/lite/local-db/resources/collection/task/file/file.storage.service.types";
import type {
   ParentType,
   TaskFile,
} from "src/app/lite/local-db/resources/collection/task/file/file.types";

@Injectable({ providedIn: "root" })
export class FileStorageService extends CollectionStorageService<
   ExtractSchema<typeof taskFileResource>,
   ExtractPrimaryKey<typeof taskFileResource>,
   ExtractDisableAutoIncrement<typeof taskFileResource>,
   ExtractIndexes<typeof taskFileResource>
> {
   public constructor() {
      super(taskFileResource);
   }

   public async addFile(file: InsertableFile): Promise<TaskFile> {
      return this.write(async (transaction) => this.add(file, transaction));
   }

   public async deleteFile(fileID: number): Promise<void> {
      return this.write(async (transaction) => this.delete(fileID, transaction));
   }

   public async updateFile(
      fileID: number,
      update: FileUpdate,
   ): Promise<TaskFile | undefined> {
      return this.write(async (transaction) => this.set(fileID, update, transaction));
   }

   public getCommentImages$(commentID: number): Observable<Array<TaskFile>> {
      return this.read$((transaction) =>
         this.getAllFromIndex$("parentType-parentID", transaction, {
            indexValue: {
               parentType: "comment-image",
               parentID: commentID,
            },
         }),
      );
   }

   public getCommentDocuments$(commentID: number): Observable<Array<TaskFile>> {
      return this.read$((transaction) =>
         this.getAllFromIndex$("parentType-parentID", transaction, {
            indexValue: {
               parentType: "comment-document",
               parentID: commentID,
            },
         }),
      );
   }

   public getAttachmentInstructionResponseImages$(
      instructionID: number,
   ): Observable<Array<TaskFile>> {
      return this.getInstructionFiles$(
         "attachment-instruction-response-image",
         instructionID,
      );
   }

   public getAttachmentInstructionResponseDocuments$(
      instructionID: number,
   ): Observable<Array<TaskFile>> {
      return this.getInstructionFiles$(
         "attachment-instruction-response-document",
         instructionID,
      );
   }

   public getAttachmentInstructionResponseFiles$(
      instructionID: number,
   ): Observable<Array<TaskFile>> {
      return this.read$((transaction) =>
         this.getAllFromIndex$("parentType-parentID", transaction, {
            indexValue: {
               parentType: "attachment-instruction-response-image",
               parentID: instructionID,
            },
         }).pipe(
            combineLatestWith(
               this.getAllFromIndex$("parentType-parentID", transaction, {
                  indexValue: {
                     parentType: "attachment-instruction-response-document",
                     parentID: instructionID,
                  },
               }),
            ),
            map(([images, documents]) => [...images, ...documents]),
         ),
      );
   }

   public getSignatureInstructionResponse$(
      instructionID: number,
   ): Observable<TaskFile | undefined> {
      return this.getInstructionFiles$(
         "signature-instruction-response",
         instructionID,
      ).pipe(map((files) => files[0]));
   }

   public getInstructionalImages$(instructionID: number): Observable<Array<TaskFile>> {
      return this.getInstructionFiles$("instructional-image", instructionID);
   }

   public getInstructionalDocuments$(instructionID: number): Observable<Array<TaskFile>> {
      return this.getInstructionFiles$("instructional-document", instructionID);
   }

   public getInstructionalFiles$(instructionID: number): Observable<Array<TaskFile>> {
      return this.read$((transaction) =>
         this.getAllFromIndex$("parentType-parentID", transaction, {
            indexValue: {
               parentType: "instructional-image",
               parentID: instructionID,
            },
         }).pipe(
            combineLatestWith(
               this.getAllFromIndex$("parentType-parentID", transaction, {
                  indexValue: {
                     parentType: "instructional-document",
                     parentID: instructionID,
                  },
               }),
            ),
            map(([images, documents]) => [...images, ...documents]),
         ),
      );
   }

   public getCompletionImages$(taskID: number): Observable<Array<TaskFile>> {
      return this.read$((transaction) =>
         this.getAllFromIndex$("parentType-parentID", transaction, {
            indexValue: {
               parentType: "completion-image",
               parentID: taskID,
            },
         }),
      );
   }

   public getCompletionDocuments$(taskID: number): Observable<Array<TaskFile>> {
      return this.read$((transaction) =>
         this.getAllFromIndex$("parentType-parentID", transaction, {
            indexValue: {
               parentType: "completion-document",
               parentID: taskID,
            },
         }),
      );
   }

   public getCompletionFiles$(taskID: number): Observable<Array<TaskFile>> {
      return this.read$((transaction) =>
         this.getAllFromIndex$("parentType-parentID", transaction, {
            indexValue: {
               parentType: "completion-image",
               parentID: taskID,
            },
         }).pipe(
            combineLatestWith(
               this.getAllFromIndex$("parentType-parentID", transaction, {
                  indexValue: {
                     parentType: "completion-document",
                     parentID: taskID,
                  },
               }),
            ),
            map(([images, documents]) => [...images, ...documents]),
         ),
      );
   }

   public getTaskImage$(taskID: number): Observable<TaskFile | undefined> {
      return this.read$((transaction) =>
         this.getAllFromIndex$("parentType-parentID", transaction, {
            indexValue: {
               parentType: "task-image",
               parentID: taskID,
            },
         }).pipe(map((files) => files[0])),
      );
   }

   private getInstructionFiles$(
      parentType: ParentType,
      instructionID: number,
   ): Observable<Array<TaskFile>> {
      return this.read$((transaction) =>
         this.getAllFromIndex$("parentType-parentID", transaction, {
            indexValue: {
               parentType,
               parentID: instructionID,
            },
         }),
      );
   }
}

type InsertableFile = Insertable<typeof taskFileResource>;

import { Injectable } from "@angular/core";
import type { Observable } from "rxjs";
import { CollectionStorageService } from "src/app/lite/local-db/resources/collection/collection.storage.service";
import type {
   ExtractDisableAutoIncrement,
   ExtractIndexes,
   ExtractPrimaryKey,
   ExtractSchema,
} from "src/app/lite/local-db/resources/collection/collection.storage.service.types";
import { instructionResource } from "src/app/lite/local-db/resources/collection/task/instruction/instruction.resource";
import type { InstructionUpdate } from "src/app/lite/local-db/resources/collection/task/instruction/instruction.storage.service.types";
import type { TaskInstruction } from "src/app/lite/local-db/resources/collection/task/instruction/instruction.types";

@Injectable({ providedIn: "root" })
export class InstructionStorageService extends CollectionStorageService<
   ExtractSchema<typeof instructionResource>,
   ExtractPrimaryKey<typeof instructionResource>,
   ExtractDisableAutoIncrement<typeof instructionResource>,
   ExtractIndexes<typeof instructionResource>
> {
   public constructor() {
      super(instructionResource);
   }

   public getInstructionsForTask$(taskID: number): Observable<Array<TaskInstruction>> {
      return this.read$((transaction) =>
         this.getAllFromIndex$("checklistID", transaction, { indexValue: taskID }),
      );
   }

   public getInstruction$(
      instructionID: number,
   ): Observable<TaskInstruction | undefined> {
      return this.read$((transaction) => this.get$(instructionID, transaction));
   }

   public async putInstruction(instruction: TaskInstruction): Promise<void> {
      await this.write(async (transaction) => this.put(instruction, transaction));
   }

   public async updateInstruction(
      instructionID: number,
      update: InstructionUpdate,
   ): Promise<void> {
      await this.write(async (transaction) =>
         this.set(instructionID, update, transaction),
      );
   }

   public async deleteInstruction(instructionID: number): Promise<void> {
      return this.write(async (transaction) => this.delete(instructionID, transaction));
   }
}

/* global module, require */

module.exports = require('./load-image');
require('./load-image-scale');
require('./load-image-meta');
require('./load-image-fetch');
require('./load-image-exif');
require('./load-image-exif-map');
require('./load-image-iptc');
require('./load-image-iptc-map');
require('./load-image-orientation');
import type { PipeTransform } from "@angular/core";
import { Pipe } from "@angular/core";

/**
 * Replacement for angularJS's "filter" filter.
 * Selects a subset of items from array and returns it as a new array.
 *
 * @deprecated
 * Angular discourages using pipes for filtering.
 * See https://angular.io/guide/styleguide#do-not-add-filtering-and-sorting-logic-to-pipes
 */
@Pipe({
   name: "filter",
   standalone: true,
})
export class FilterArrayPipe implements PipeTransform {
   public transform<T = any>(
      arr: Array<T>,
      prop: any,
      strict?: boolean,
      filterDeep?: boolean,
   ): Array<T> {
      if (!Array.isArray(arr) || arr.length === 0) {
         return arr;
      }
      if (typeof prop === "object") {
         return filterByObject(arr, prop, strict);
      }
      return filterByNonObject(arr, prop, strict, filterDeep);
   }
}

const filterByObject = (arr, prop, strict) => {
   let tempArr = arr;
   const keys = Object.keys(prop);
   if (keys.length > 0) {
      const keyLength = keys.length;
      for (let index = 0; index < keyLength; index++) {
         tempArr = filterByObjectProp(tempArr, prop, strict, index);
      }
   }
   return tempArr;
};

const filterByObjectProp = (arr, prop, strict, objIndex) => {
   const key = Object.keys(prop)[objIndex];
   let tempProp = prop[key];
   let isNotEquals = false;
   if (typeof tempProp === "string" && tempProp.startsWith("!")) {
      isNotEquals = true;
      tempProp = tempProp.slice(1, tempProp.length);
   }
   if (strict) {
      //This is essentially strict comparison of expected and actual. *From AngularJS docs*
      if (isNotEquals) {
         return !arr.filter((obj) => obj[key] === tempProp);
      }
      return arr.filter((obj) => obj[key] === tempProp);
   }
   // This will look for a substring match in a case insensitive way. *From AngularJS docs
   return arr.filter((obj) => {
      if (
         typeof obj[key] === "string" &&
         typeof tempProp === "string" &&
         key !== "timezone"
      ) {
         if (isNotEquals) {
            return !obj[key].toLowerCase().includes(tempProp.toLowerCase());
         }
         return obj[key].toLowerCase().includes(tempProp.toLowerCase());
      }
      if (isNotEquals) {
         return !(obj[key] == tempProp);
      }
      return obj[key] == tempProp;
   });
};

const filterByNonObject = (arr, prop, strict, filterDeep) => {
   let tempProp = prop;
   let isNotEquals = false;
   if (tempProp == undefined) {
      return arr;
   }
   if (typeof tempProp === "string" && tempProp.startsWith("!")) {
      isNotEquals = true;
      tempProp = tempProp.slice(1, tempProp.length);
   }
   if (strict) {
      //This is essentially strict comparison of expected and actual. *From AngularJS docs*
      return arr.filter((obj) =>
         Object.keys(obj).some((key) => {
            if (isNotEquals) {
               return !(obj[key] === prop);
            }
            return obj[key] === prop;
         }),
      );
   }
   // This will look for a substring match in a case insensitive way. *From AngularJS docs
   return arr.filter((obj) => {
      return filterLoose(obj, prop, isNotEquals, filterDeep);
   });
};

const filterLoose = (obj, prop, isNotEquals, filterDeep) => {
   return Object.keys(obj).some((key) => {
      if (filterDeep && obj[key] && typeof obj[key] === "object") {
         return filterLoose(obj[key], prop, isNotEquals, filterDeep);
      }
      if (
         typeof obj[key] === "string" &&
         typeof prop === "string" &&
         key !== "timezone"
      ) {
         if (isNotEquals) {
            return !obj[key].toLowerCase().includes(prop.toLowerCase());
         }
         return obj[key].toLowerCase().includes(prop.toLowerCase());
      }
      if (isNotEquals) {
         return !(obj[key] == prop);
      }
      return obj[key] == prop;
   });
};

import {
   Directive,
   ElementRef,
   inject,
   type OnDestroy,
   type OnInit,
} from "@angular/core";
import { isAndroidApp } from "@limblecmms/lim-ui";
import type { KeyboardInfo } from "median-js-bridge/dist/commands/general";

@Directive({
   standalone: true,
   selector: "[blurOnKeyboardClose]",
})
export class BlurOnKeyboardCloseDirective implements OnInit, OnDestroy {
   private readonly elementRef = inject(ElementRef);

   public ngOnInit() {
      if (isAndroidApp() === false) return;
      this.listenForKeyboardClose();
   }

   public ngOnDestroy() {
      if (isAndroidApp() === false) return;
      // Median's type in this case is different from what is described
      // in their docs, so we cannot use the NPM package here.
      (window as any).median.keyboard.listen("");
   }

   private listenForKeyboardClose() {
      // First, unregister any existing listener to avoid stacking multiple listeners
      (window as any).median.keyboard.listen("");

      // Then register a new listener
      (window as any).median.keyboard.listen((keyboardState: KeyboardInfo) => {
         if (keyboardState.visible === false) {
            this.blurElement();
         }
         // Re-register the listener for the next event, but first unregister to avoid stacking
         this.listenForKeyboardClose();
      });
   }

   private blurElement() {
      const element = this.elementRef.nativeElement as HTMLElement;
      if (element && typeof element.blur === "function") {
         element.blur();
      }
   }
}

import type { assetFieldDefinitionSchema } from "src/app/assets/schemata/fields/asset-field.schema";
import type { z } from "zod";

type AssetFieldDefinitionSchema = z.infer<typeof assetFieldDefinitionSchema>;

export enum AssetFieldDataType {
   Text = "text",
   Date = "date",
   Pictures = "pictures",
   Documents = "documents",
   Number = "number",
   Currency = "currency",
   Dropdown = "dropdown",
}

export type AssetFieldDefinition = AssetFieldDefinitionSchema & {
   dataType: AssetFieldDataType;
};

export type AssetFieldDefinitionRequest = Omit<AssetFieldDefinition, "fieldID">;

export type AssetTemplateField = AssetFieldDefinition & {
   templateID: number;
   viewOrder: number;
};

export enum AssetFieldScopeType {
   Standardized = "standardized",
   Local = "local",
}

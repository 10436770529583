import { assetFieldValueInitializer } from "src/app/lite/local-db/resources/collection/asset/field/value/asset-field-value.initializer";
import { assetFieldValueSchema } from "src/app/lite/local-db/resources/collection/asset/field/value/asset-field-value.schema";
import { CollectionResource } from "src/app/lite/local-db/resources/collection/collection.resource";

export const assetFieldValueResource = new CollectionResource({
   name: "assetFieldValues",
   validation: assetFieldValueSchema,
   primaryKey: "valueID",
   indexes: [] as const,
   initialize: assetFieldValueInitializer,
   batchSize: 1100,
});

import { NgClass } from "@angular/common";
import type { AfterViewInit, OnInit } from "@angular/core";
import {
   Component,
   ElementRef,
   Renderer2,
   computed,
   inject,
   input,
   signal,
} from "@angular/core";
import {
   LoadingAnimationComponent,
   PrimaryButtonComponent,
   SecondaryButtonComponent,
   TableButtonComponent,
   type Aliases,
} from "@limblecmms/lim-ui";
import { type FilePond, Status } from "filepond";
import type { ButtonType } from "src/app/files/components/fileUploader/fileUploader.element.component.types";
import type { UploadType } from "src/app/files/services/file-service.types";
import { ManageFiles } from "src/app/files/services/manageFiles";
import { ManageLang } from "src/app/languages/services/manageLang";
import { AndroidFileUploadMenuComponent } from "src/app/mobile/files/android-file-upload-menu/android-file-upload-menu.component";
import { AlertService } from "src/app/shared/services/alert.service";
import { AndroidFeatureAvailabilityService } from "src/app/shared/services/mobile-native-device-info/android-feature-availability/android-feature-availability.service";

@Component({
   selector: "file-uploader",
   templateUrl: "./fileUploader.element.component.html",
   styleUrls: ["./fileUploader.element.component.scss"],
   imports: [
      PrimaryButtonComponent,
      NgClass,
      TableButtonComponent,
      SecondaryButtonComponent,
      LoadingAnimationComponent,
      AndroidFileUploadMenuComponent,
   ],
})
export class FileUploader implements OnInit, AfterViewInit {
   private readonly manageFiles = inject(ManageFiles);
   private readonly alertService = inject(AlertService);
   private readonly renderer = inject(Renderer2);
   private readonly elementRef = inject(ElementRef);
   private readonly manageLang = inject(ManageLang);
   private readonly androidFeatureAvailabilityService = inject(
      AndroidFeatureAvailabilityService,
   );

   public readonly buttonType = input<ButtonType>("secondary");
   public readonly label = input<string>();
   public readonly uploadObject = input.required<any>(); // TODO(FORGE-125): Replace with FileUploaderModel type
   // eslint-disable-next-line angular/no-input-rename -- added to not break the existing code while updating to Angular 19
   public readonly uploadType = input<UploadType>("all", { alias: "accept" });
   public readonly icon = input<Aliases>();
   public readonly loadingIndicator = input<boolean>(true);

   public uploading: boolean = false;
   public uploaderInterval: NodeJS.Timeout | undefined = undefined;

   protected readonly isAndroidMenuNeeded = signal(false);
   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   private readonly hasMultipleUploadOptions = computed(
      () =>
         this.uploadType() === "all" ||
         this.uploadType() === "images" ||
         this.uploadType() === "allVideo",
   );

   public constructor() {
      this.setIsAndroidMenuNeeded();
   }

   public ngOnInit(): void {
      this.uploadObject().errorHandler = (errorMsg: string) => {
         setTimeout(() => {
            this.alertService.addAlert(errorMsg, "warning", 5000);
         }, 0);
      };
   }

   private getUploaderByConfiguredType(): FilePond {
      switch (this.uploadType()) {
         case "images":
            return this.manageFiles.createImageUploader;
         case "documents":
            return this.manageFiles.createDocUploader;
         case "import":
            return this.manageFiles.createImportUploader;
         case "videos":
            return this.manageFiles.createVideoUploader;
         case "all":
            return this.manageFiles.createFileUploader;
         case "allVideo":
            return this.manageFiles.createFileVideoUploader;
         default:
            throw new Error(`Invalid upload type: ${this.uploadType()}`);
      }
   }

   protected uploadFromCamera(): void {
      this.uploadFile(this.manageFiles.createCameraUploader);
   }
   protected uploadFromGallery(): void {
      this.uploadFile(this.manageFiles.createImageUploader);
   }
   protected uploadFromFileBrowser(): void {
      this.uploadFile(this.manageFiles.createDocUploader);
   }
   protected uploadByConfiguredType(): void {
      const uploader = this.getUploaderByConfiguredType();
      this.uploadFile(uploader);
   }

   uploaded = () => {
      clearInterval(this.uploaderInterval);
      this.uploading = false;
   };

   protected uploadFile(uploader: FilePond): void {
      if (this.uploadObject().preventMoreUploads) {
         this.alertService.addAlert(
            `${this.lang().FileNumberLimitMsg}  ${this.uploadObject().uploadLimit}.`,
            "warning",
            5000,
         );
         return;
      }

      this.configureUploader(uploader);

      uploader.browse();
      this.manageFiles.setUploadServerSettings(
         uploader,
         this.uploadObject(),
         this.uploaded,
      );
      this.uploaderInterval = setInterval(() => {
         this.uploading = uploader.status === Status.BUSY;
      }, 500);
   }

   private configureUploader(uploader: FilePond): void {
      this.configureFileLimit(uploader);
      uploader.files = [];
   }

   private configureFileLimit(uploader: FilePond): void {
      if (!this.uploadObject().uploadLimit) return;

      uploader.maxFiles = this.uploadObject().uploadLimit;
      uploader.onwarning = (error) => {
         if (error.body !== "Max files") return;

         this.alertService.addAlert(
            `${this.lang().TryingToUploadTooManyFilesMsg} ${this.uploadObject().uploadLimit}.`,
            "warning",
            5000,
         );
      };
   }

   public ngAfterViewInit(): void {
      ["dragenter", "dragover", "dragleave", "drop"].forEach((eventName) => {
         this.renderer.listen(this.elementRef.nativeElement, eventName, preventDefaults);
      });

      //keeps the dropped files from going to the browser to show
      function preventDefaults(event) {
         event.preventDefault();
         event.stopPropagation();
      }

      const addHighlight = () => {
         this.renderer.addClass(this.elementRef.nativeElement, "highlight");
      };

      const removeHighlight = () => {
         this.renderer.removeClass(this.elementRef.nativeElement, "highlight");
      };

      ["dragenter", "dragover"].forEach((eventName) => {
         this.renderer.listen(this.elementRef.nativeElement, eventName, addHighlight);
      });
      ["dragleave", "drop"].forEach((eventName) => {
         this.renderer.listen(this.elementRef.nativeElement, eventName, removeHighlight);
      });

      const handleDrop = (event) => {
         const dataTransfer = event.dataTransfer;
         const files = dataTransfer.files;

         for (const file of files) {
            this.addFileToUploader(file, "drop");
         }
      };

      this.renderer.listen(this.elementRef.nativeElement, "drop", handleDrop);

      //currently only allows one image to be copy and pasted at a time.
      //maybe with a hidden input element with set to accept multiple copy and paste for multiple might work
   }

   private async setIsAndroidMenuNeeded(): Promise<void> {
      const isFileUploadMenuAvailable =
         await this.androidFeatureAvailabilityService.hasFileUploadMenu();

      this.isAndroidMenuNeeded.set(
         isFileUploadMenuAvailable && this.hasMultipleUploadOptions(),
      );
   }

   addFileToUploader = (file, source) => {
      this.uploading = true;
      const uploader = this.getUploaderByConfiguredType();

      this.manageFiles.setUploadServerSettings(
         uploader,
         this.uploadObject(),
         this.uploaded,
      );
      if (source !== "drop") return;

      uploader.addFile(file);
   };

   watchImagePaste = (event) => {
      event.preventDefault();
      event.stopPropagation();

      for (const item of event.clipboardData.items) {
         if (item.type.indexOf("image") != -1) {
            const blob = item.getAsFile();
            this.addFileToUploader(blob, "paste");
         }
      }
   };

   removePaste = () => {
      window.removeEventListener("paste", this.watchImagePaste, false);
   };

   allowPaste = () => {
      window.addEventListener("paste", this.watchImagePaste, false);
   };
}

import { computed, inject } from "@angular/core";
import { Pipe, type PipeTransform } from "@angular/core";
import { ManageLang } from "src/app/languages/services/manageLang";

@Pipe({
   name: "secToHoursMinutes",
   standalone: true,
})
export class SecToHoursMinutesPipe implements PipeTransform {
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public transform(inputIn: unknown, format: string = "long"): string | undefined {
      if (!this.isValid(inputIn)) {
         return undefined;
      }
      return this.secToHoursMinutes(inputIn, format);
   }

   private isValid(input: unknown): input is number {
      if (typeof input === "number") {
         if (isNaN(input)) {
            return false;
         }
         return true;
      }
      return false;
   }

   private secToHoursMinutes(timeInSeconds: number, format: string = "long"): string {
      const hours = Number(Math.floor(timeInSeconds / 3600));
      const minutes = Number(Math.floor((timeInSeconds / 60) % 60));
      let formattedString = "";
      // example: 3hr and 14 min
      if (format === "long") {
         formattedString = `${hours} ${this.lang().hr} ${this.lang().and} ${minutes} ${this.lang().min}`;
      }
      // example: 3:14 hr
      if (format === "short") {
         formattedString = `${hours}:${minutes > 0 ? minutes : "00"} ${this.lang().hr}`;
      }
      return formattedString;
   }
}

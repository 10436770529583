import { CollectionResource } from "src/app/lite/local-db/resources/collection/collection.resource";
import { instructionOptionInitializer } from "src/app/lite/local-db/resources/collection/task/instruction/instruction-option/instruction-option.initializer";
import { instructionOptionSchema } from "src/app/lite/local-db/resources/collection/task/instruction/instruction-option/instruction-option.schema";

export const instructionOptionResource = new CollectionResource({
   name: "instructionOptions",
   validation: instructionOptionSchema,
   primaryKey: "itemOptionID",
   indexes: ["itemID"] as const,
   initialize: instructionOptionInitializer,
});

import { z } from "zod";

export const currentUserSchemaStrict = z.object({
   userID: z.number(),
   customerID: z.number(),
   isSuperUser: z.boolean(),
   username: z.string(),
   email: z.string(),
   name: z.object({
      first: z.string(),
      last: z.string(),
   }),
});

[
   { "name": "celebrate-1-min.gif", "description": "Woot you're done!" },
   { "name": "celebrate-2-min.gif", "description": "Finally done!" },
   { "name": "celebrate-3-min.gif", "description": "Woot you're done!" },
   {
      "name": "celebrate-5-min.gif",
      "description": "You're done!  I bow before your awesomeness!"
   },
   {
      "name": "celebrate-6-min.gif",
      "description": "You're done!  Party time!"
   },
   { "name": "celebrate-7-min.gif", "description": "Finally done!" },
   { "name": "congrats-2-min.gif", "description": "Awesome job!" },
   {
      "name": "congrats-3-min.gif",
      "description": "You're done!  Happy Dance!"
   },
   {
      "name": "congrats-4-min.gif",
      "description": "You're done!"
   },
   {
      "name": "congrats-5-min.gif",
      "description": "You're done!"
   },
   {
      "name": "congrats-8-min.gif",
      "description": "You're done! "
   },
   {
      "name": "congrats-9-min.gif",
      "description": "You're done! Nuff said"
   },
   {
      "name": "congrats-10-min.gif",
      "description": "You're done! Awesome job!"
   },
   {
      "name": "congrats-11-min.gif",
      "description": "You're done! Awesome job!"
   },
   {
      "name": "congrats-12-min.gif",
      "description": "You're done! I approve of the job you did!"
   },
   {
      "name": "congrats-13-min.gif",
      "description": "You're done! Happy dance!"
   },
   {
      "name": "congrats-14-min.gif",
      "description": "You're done! Happy dance!"
   },
   {
      "name": "congrats-min.gif",
      "description": "You're done! Happy dance!"
   },
   {
      "name": "funny-1-min.gif",
      "description": "Nice job! Here's your daily pick me up"
   },
   {
      "name": "funny-2-min.gif",
      "description": "Nice job! Here's your daily pick me up"
   },
   {
      "name": "funny-3-min.gif",
      "description": "Nice job! Here's your daily pick me up"
   },
   {
      "name": "funny-4-min.gif",
      "description": "Nice job! Here's your daily pick me up"
   },
   {
      "name": "funny-5-min.gif",
      "description": "Nice job! Here's your daily pick me up"
   },
   {
      "name": "funny-6-min.gif",
      "description": "Nice job! Here's your daily pick me up"
   },
   {
      "name": "funny-7-min.gif",
      "description": "Nice job! Here's your daily pick me up"
   },
   {
      "name": "funny-8-min.gif",
      "description": "Nice job! Here's your daily pick me up"
   },
   {
      "name": "funny-9-min.gif",
      "description": "Nice job! Here's your daily pick me up"
   },
   {
      "name": "funny-10-min.gif",
      "description": "Nice job! Here's your daily pick me up"
   },
   {
      "name": "funny-11-min.gif",
      "description": "Nice job! Here's your daily pick me up"
   },
   {
      "name": "funny-12-min.gif",
      "description": "Nice job! Here's your daily pick me up"
   },
   {
      "name": "funny-16-min.gif",
      "description": "Nice job! Here's your daily pick me up"
   },
   {
      "name": "funny-17-min.gif",
      "description": "Nice job! Here's your daily pick me up"
   },
   {
      "name": "funny-18-min.gif",
      "description": "Nice job! Here's your daily pick me up"
   },
   {
      "name": "funny-19-min.gif",
      "description": "Dance if you completed a task!"
   },
   {
      "name": "funny-20-min.gif",
      "description": "Awesome job!"
   },
   {
      "name": "funny-21-min.gif",
      "description": "Nice job! Here's your daily pick me up"
   },
   {
      "name": "funny-22-min.gif",
      "description": "Nice job! Here's your daily pick me up"
   },
   {
      "name": "funny-23-min.gif",
      "description": "Nice job! Here's your daily pick me up"
   },
   {
      "name": "funny-24-min.gif",
      "description": "Thank you for your hard work!"
   },
   {
      "name": "funny-25-min.gif",
      "description": "Nice job! Here's your daily pick me up"
   },
   {
      "name": "funny-26-min.gif",
      "description": "Nice job! Here's your daily pick me up"
   },
   {
      "name": "funny-27-min.gif",
      "description": "Nice job! Here's your daily pick me up"
   },
   {
      "name": "funny-28-min.gif",
      "description": "Nice job! Here's your daily pick me up"
   },
   {
      "name": "funny-29-min.gif",
      "description": "Nice job! Here's your daily pick me up"
   },
   {
      "name": "funny-30-min.gif",
      "description": "Nice job! Here's your daily pick me up"
   },
   {
      "name": "funny-31-min.gif",
      "description": "Nice job! Here's your daily pick me up"
   },
   {
      "name": "funny-33-min.gif",
      "description": "Nice job! Here's your daily pick me up"
   },
   {
      "name": "funny-35-min.gif",
      "description": "Nice job! Here's your daily pick me up"
   },
   {
      "name": "funny-36-min.gif",
      "description": "Nice job! Here's your daily pick me up"
   },
   {
      "name": "funny-37-min.gif",
      "description": "Don’t miss a PM... you’ll regret it later"
   },
   {
      "name": "funny-38-min.gif",
      "description": "Machines always work as designed, right?"
   },
   {
      "name": "funny-39-min.gif",
      "description": "Nice job! Here's your daily pick me up"
   },
   {
      "name": "funny-40-min.gif",
      "description": "Nice job! Here's your daily pick me up"
   },
   {
      "name": "funny-41-min.gif",
      "description": "Nice job! Here's your daily pick me up"
   },
   {
      "name": "AddMorePower-min.gif",
      "description": "Add More Power!"
   },
   {
      "name": "AlcoholAndForkliftsDontMix-min.gif",
      "description": "Alcohol and forklifts don't mix."
   },
   {
      "name": "AwesomeJob-joker-min.gif",
      "description": "Smile, you completed a WO."
   },
   {
      "name": "BalanceRotorsBeforeResumingOperation-min.gif",
      "description": "Balance roters before resuming operation"
   },
   {
      "name": "BasicWorkAdvice-123sz-min.gif",
      "description": "Basic work advice"
   },
   {
      "name": "CelebrateTheRightWay-ko1z-min.gif",
      "description": "You make maintenance look good!"
   },
   {
      "name": "DontOperateMachineryAndYourPhoneAtTheSameTime-zsk-min.gif",
      "description": "Don't operate machinery and your phone at the same time"
   },
   {
      "name": "FinallyFinishedWoot-szz-min.gif",
      "description": "Finally finished.  Woot!"
   },
   {
      "name": "FinallyFinishedYes-111-min.gif",
      "description": "Finally finished.  Yes!"
   },
   {
      "name": "FinallyFinshedYes-31zs-min.gif",
      "description": "Finally finished.  Yes!"
   },
   {
      "name": "FriendlyFire-kzos-min.gif",
      "description": "Friendly Fire!"
   },
   {
      "name": "GoodWork-kzkosd-min.gif",
      "description": "Good word!"
   },
   {
      "name": "GoYou-kzoks-min.gif",
      "description": "Go you!"
   },
   {
      "name": "HandyProductionEquipment-io13-min.gif",
      "description": "Handy production equipment"
   },
   {
      "name": "HastaLaVistaWorkOrder-ozk-min.gif",
      "description": "¡Hasta la vista, Work Order!"
   },
   {
      "name": "HaveYouTriedAddingOilMaam-skz-min.gif",
      "description": "Have you tried adding oil?"
   },
   {
      "name": "IfYouAreGoingToFixItDoItRight-mzs-min.gif",
      "description": "If you are going to fix it, do it right"
   },
   {
      "name": "IMadeTheWOItCouldntRefuse-k1s-min.gif",
      "description": "I made the WO an offer it couldn’t refuse."
   },
   {
      "name": "LearnToUseToolsProperly-3az-min.gif",
      "description": "Learn to use tools properly"
   },
   {
      "name": "MaintenanceTechsAreHeros-ssz-min.gif",
      "description": "Maintenance Techs are heroes"
   },
   {
      "name": "MeltingR2D2-sze-min.gif",
      "description": "Melting R2D2"
   },
   {
      "name": "MindBlown-koz-min.gif",
      "description": "Mind Blown"
   },
   {
      "name": "OtterHighFive-zzwea-min.gif",
      "description": "Otter High Five!"
   },
   {
      "name": "PartyLikeItIs1977-mzk-min.gif",
      "description": "Party like it is 1977!"
   },
   {
      "name": "PointsForTechnique-kloia-min.gif",
      "description": "Points for technique!"
   },
   {
      "name": "PracticeMakesPerfect-asko-min.gif",
      "description": "Practice makes perfect!"
   },
   {
      "name": "PracticePowerToolSafety-ekoz-min.gif",
      "description": "Practice power tool safety!"
   },
   {
      "name": "ProductionEquipmentNeedsOil-kzos-min.gif",
      "description": "Production equipment needs oil"
   },
   {
      "name": "ShimmyIfYouCompletedAWO-saz-min.gif",
      "description": "Shimmy if you completed a WO!"
   },
   {
      "name": "ShowingOffAroundMachineryAlwaysEndsBad-ozw-min.gif",
      "description": "Showing off with machinery always ends bad!"
   },
   {
      "name": "SmarterThanTheAverageBear-sz-min.gif",
      "description": "You are smarter than the average bear."
   },
   {
      "name": "TakeADeepBreathTheTaskIsComplete-kzosd-min.gif",
      "description": "Take a deep breath the Task is complete!"
   },
   {
      "name": "ThanksForYourHardWork-ksz-min.gif",
      "description": "Thanks for your hard work!"
   },
   {
      "name": "ThatsWhereThePartWent-min.gif",
      "description": "That's where the part went!"
   },
   {
      "name": "TheresAlwaysSomeoneWhoStealsTheEasyWOs-kzo-min.gif",
      "description": "There's always someone who steals the easy WOs!"
   },
   {
      "name": "TheyLinedTheTasksUpAndYouKnockedEmDown-zkos-min.gif",
      "description": "They lined the Tasks up and you knocked em down!"
   },
   {
      "name": "VeryImpressive...-koziwe-min.gif",
      "description": "Very impressive!"
   },
   {
      "name": "VerySkilledYouAre-pqka-min.gif",
      "description": "Very skilled, you are!"
   },
   {
      "name": "WatchingYouWorkMakesMeTired-ko1z-min.gif",
      "description": "Watching you work makes me tired..."
   },
   {
      "name": "WhenYouAreGoodAtWhatYouDoWhyStop-kod-min.gif",
      "description": "When you are good at what you do, why stop?"
   },
   {
      "name": "WheresTheNextTask-koze-min.gif",
      "description": "Where's the next Task!"
   },
   {
      "name": "WhyPreventiveMaintenanceIsImportant-zkos2-min.gif",
      "description": "Why preventive maintenance is important!"
   },
   {
      "name": "WorkSmarterNotHarder-kzd-min.gif",
      "description": "Work smarter, not harder"
   },
   {
      "name": "WorkSmarterNotHarder-likz-min.gif",
      "description": "Work smarter, not harder"
   },
   {
      "name": "YouAreGoodAtWhatYouDo-ieaw3-min.gif",
      "description": "You are good at what you do!"
   },
   {
      "name": "YouAreSoGoodAtYourJobItsScary-jdk324-min.gif",
      "description": "You're so good at your job, it's scary"
   },
   {
      "name": "YouAreSoGoodAtYourJobItsScary-zsdk123-min.gif",
      "description": "You're so good at your job, it's scary"
   },
   {
      "name": "YouDeserveAVacation-k1q3-min.gif",
      "description": "You deserve a vacation!"
   },
   {
      "name": "YouJustGotRickRolled-321ka-min.gif",
      "description": "You just got Rick rolled!"
   },
   {
      "name": "YoureDoneWoot-minion-tes-min.gif",
      "description": "You're done!  Woot!"
   },
   {
      "name": "YoureDoneWoot-napol-min.gif",
      "description": "You're done!  Woot!"
   },
   {
      "name": "YoureFinallyDone-thor-min.gif",
      "description": "You're finally done!"
   },
   {
      "name": "YoureGoodAtThis-szk-min.gif",
      "description": "You're good at this!"
   },
   {
      "name": "YoureRockingIt-zkwz-min.gif",
      "description": "You're rocking it!"
   },
   {
      "name": "YoureSuchAPro-zkd-min.gif",
      "description": "You're such a pro!"
   },
   {
      "name": "YoureJobCouldBeWorse-kzo1-min.gif",
      "description": "You're job could be worse!"
   },
   {
      "name": "YouSureAreAwesome-kozkow-min.gif",
      "description": "You sure are awesome!"
   },
   {
      "name": "Yummmm-koz23-min.gif",
      "description": "Yummmmmmm"
   },
   {
      "name": "COVID-PPE-min.gif",
      "description": "Covid PPE"
   },
   {
      "name": "Goodplanning-min.gif",
      "description": "Good Planning"
   },
   {
      "name": "Like-a-boss-min.gif",
      "description": "Like a boss!"
   },
   {
      "name": "WhenYouKnowYoureCool-min.gif",
      "description": "When you know you're cool"
   },
   {
      "name": "You_reOnFire-min.gif",
      "description": "You're on fire!"
   },
   {
      "name": "chilling-min.gif",
      "description": "Chillin'"
   },
   {
      "name": "covidHammock-min.gif",
      "description": "Covid hammock"
   },
   {
      "name": "dreamTeam-min.gif",
      "description": "Dream Team!"
   },
   {
      "name": "informant-min.gif",
      "description": "The informant!"
   },
   {
      "name": "dailyPickMeUp-min.gif",
      "description": "Daily pick-me-up"
   },
   {
      "name": "goodWork-min.gif",
      "description": "High Five and Take a Bow!"
   },
   {
      "name": "greatWork.gif",
      "description": "Great Work!"
   },
   {
      "name": "gutterFall-min.gif",
      "description": "Oops!"
   },
   {
      "name": "ISawATiger-min.gif",
      "description": "I saw a Tiger..."
   },
   {
      "name": "rockAndBowl-min.gif",
      "description": "Rock and \"Bowl\"!"
   },
   {
      "name": "soImpressive.gif",
      "description": "So impressive!"
   },
   {
      "name": "takeTimeToStretch-min.gif",
      "description": "Take some time to stretch."
   },
   {
      "name": "thanksForCompletingTask-min.gif",
      "description": "Thanks for completing a task!"
   },
   {
      "name": "whenIsLunch-min.gif",
      "description": "When is lunch"
   },
   {
      "name": "youAreAppreciated-min.gif",
      "description": "You are appreciated"
   },
   {
      "name": "youBringTheThunder-min.gif",
      "description": "You bring the thunder!"
   },
   {
      "name": "youMakeMeSmile-min.gif",
      "description": "You make me smile!"
   },
   {
      "name": "enjoyTheSimplePleasures-min.gif",
      "description": "Enjoy the simple pleasures"
   },
   {
      "name": "hello-min.gif",
      "description": "Daily pick-me-up"
   },
   {
      "name": "dailyPickMeUp_2-min.gif",
      "description": "Daily pick-me-up"
   },
   {
      "name": "reverseDribble-min.gif",
      "description": "Reverse dribble"
   },
   {
      "name": "noSoupForYou-min.gif",
      "description": "No soup for you!"
   },
   {
      "name": "completeATask-min.gif",
      "description": "Yay you completed a task!"
   },
   {
      "name": "dailyPickMeUp_3-min.gif",
      "description": "Daily pick-me-up"
   },
   {
      "name": "soPowerful-min.gif",
      "description": "You are so powerful"
   },
   {
      "name": "dailyPickMeUp_4-min.gif",
      "description": "Daily pick-me-up"
   },
   {
      "name": "tenor-min.gif",
      "description": "Great work!"
   },
   {
      "name": "taskVan-min.gif",
      "description": "There is a task to do in the van down by the river"
   },
   {
      "name": "completeTask-min.gif",
      "description": "You completed a task!"
   },
   {
      "name": "greatWork_3-min.gif",
      "description": "Great work!"
   },
   {
      "name": "crossStreams-min.gif",
      "description": "Don’t cross the streams"
   },
   {
      "name": "dance-min.gif",
      "description": "Dance! You completed a task!"
   },
   {
      "name": "bobRoss-min.gif",
      "description": "Daily pick-me-up"
   },
   {
      "name": "scary dog-min.gif",
      "description": "Daily pick-me-up"
   },
   {
      "name": "tyrant-min.gif",
      "description": "Daily pick-me-up"
   },
   {
      "name": "turtle-min.gif",
      "description": "Happy turtle shower"
   },
   {
      "name": "Oops-min.gif",
      "description": "Oops!"
   },
   {
      "name": "fire-min.gif",
      "description": "Daily pick-me-up"
   },
   {
      "name": "quick, run-min.gif",
      "description": "Run!!!"
   },
   {
      "name": "parents-min.gif",
      "description": "Parents to the rescue"
   },
   {
      "name": "rodeo-min.gif",
      "description": "New rodeo event"
   },
   {
      "name": "lion-min.gif",
      "description": "Daily pick-me-up"
   },
   {
      "name": "tickle fish-min.gif",
      "description": "Tickle fish"
   },
   {
      "name": "crew replacement-min.gif",
      "description": "Crew replacement"
   },
   {
      "name": "scary fish-min.gif",
      "description": "Daily pick-me-up"
   },
   {
      "name": "Bobbing for gators-min.gif",
      "description": "Bobbing for gators"
   },
   {
      "name": "pick me up-min.gif",
      "description": "Spider Monkey!"
   },
   {
      "name": "tackle-min.gif",
      "description": "There is only room for one of us in this family!"
   },
   {
      "name": "Work for food-min.gif",
      "description": "Work for food"
   },
   {
      "name": "Working from home-min.gif",
      "description": "Working from home..."
   },
   {
      "name": "McBurglar-min.gif",
      "description": "McBurglar"
   },
   {
      "name": "room-min.gif",
      "description": "There is only room for one of us in this house!"
   },
   {
      "name": "purpose-min.gif",
      "description": "Next time buy me the canned food!"
   },
   {
      "name": "take down-min.gif",
      "description": "Take down!"
   },
   {
      "name": "goats-min.gif",
      "description": "Even goats have brothers"
   },
   {
      "name": "sneak-min.gif",
      "description": "Sneak attack"
   },
   {
      "name": "mans best friend-min.gif",
      "description": "Man's best friend"
   },
   {
      "name": "take that-min.gif",
      "description": "Take that!"
   },
   {
      "name": "James brown-min.gif",
      "description": "That James Brown funk!"
   },
   {
      "name": "moon walk-min.gif",
      "description": "Moon Walk"
   },
   {
      "name": "Eeee-ooo-min.gif",
      "description": "EEEEEE-O"
   },
   {
      "name": "rocky-min.gif",
      "description": "You are a champion!"
   },
   {
      "name": "whats up doc-min.gif",
      "description": "What's up doc?"
   },
   {
      "name": "what-min.gif",
      "description": "Daily pick-me-up"
   },
   {
      "name": "sup-min.gif",
      "description": "Sup?"
   },
   {
      "name": "lesson-min.gif",
      "description": "The lesson you only need to learn once."
   },
   {
      "name": "chance-min.gif",
      "description": "Daily pick-me-up"
   },
   {
      "name": "one million dollars-min.gif",
      "description": "Daily pick-me-up"
   },
   {
      "name": "three point turn-min.gif",
      "description": "Daily pick-me-up"
   },
   {
      "name": "you are awesome-min.gif",
      "description": "You are awesome!"
   },
   {
      "name": "Thumbs up chuck-min.gif",
      "description": "Great work!"
   },
   {
      "name": "great work-min.gif",
      "description": "Great work!"
   }
]

<lim-ui-modal class="complete-task-gif-modal" [small]="true">
   <lim-ui-basic-modal-header
      [title]="lang()?.TaskCompletedReward ?? ''"
      (closeModal)="close()"
   />

   <lim-ui-modal-body>
      <lim-ui-panel>
         <div class="complete-task-modal-container">
            <label>{{ this.message }}</label>

            <img class="completed-task-gif" [src]="imageUrl" [name]="this.gifName" />

            <div id="completedTaskGifVoteData" class="completed-task-vote-data">
               <lim-ui-icon-button
                  icon="thumbsUp"
                  iconColor="success"
                  (click)="voteCongrats(1)"
               />

               <lim-ui-icon-button
                  icon="thumbsDown"
                  iconColor="danger"
                  (click)="voteCongrats(0)"
               />

               <lim-ui-primary-button
                  icon="shareFromSquareRegular"
                  (click)="shareCompletionGif(); $event.stopPropagation()"
                  >{{ lang().Share }}</lim-ui-primary-button
               >
            </div>

            <lim-ui-outlined-button
               (click)="disableCongratsMsg(); $event.stopPropagation()"
               >{{ lang()?.TurnOffTheseMessages }}</lim-ui-outlined-button
            >
         </div>
      </lim-ui-panel>
   </lim-ui-modal-body>
</lim-ui-modal>

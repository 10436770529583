import { Component, inject, input, output } from "@angular/core";
import { ListIconComponent } from "@limblecmms/lim-ui";
import { TranslationService } from "src/app/languages/translation/translation.service";

@Component({
   selector: "android-file-upload-menu-overlay",
   templateUrl: "./android-file-upload-menu-overlay.component.html",
   styleUrls: ["./android-file-upload-menu-overlay.component.scss"],
   imports: [ListIconComponent],
})
export class AndroidFileUploadMenuOverlayComponent {
   public readonly takePhoto = output();
   public readonly addImage = output();
   public readonly addDocument = output();

   protected readonly placement = input.required<"above" | "below">();
   protected readonly isDocumentOptionIncluded = input.required<boolean>();

   protected readonly i18n = inject(TranslationService).i18n;
}

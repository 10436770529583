import type { CollectionRequest } from "src/app/lite/local-db/resources/collection/collection.resource.utils.types";

export const instructionOptionInitializer: CollectionRequest = {
   url: "tasks/instructions/options",
   params: {
      taskStatus: "uncompleted",
      excludeDeleted: true,
      excludeTemplates: true,
   },
};

import { Injectable } from "@angular/core";
import type { TaskTimerComponent } from "src/app/tasks/timer/task-timer/task-timer.component";
import type { ElapsedTimeDuration } from "src/app/tasks/types/timer-duration/elapsed-time-duration.types";

@Injectable({ providedIn: "root" })
export class TimerDurationService {
   private timerDuration: ElapsedTimeDuration | null = null;

   public storeDuration(timer: TaskTimerComponent, taskID: number): void {
      const now = new Date().getTime();
      const duration = timer.getElapsedTime();
      this.timerDuration = { taskID: taskID, timestamp: now, duration: duration };
   }

   public getDuration(timer: TaskTimerComponent, taskID: number): void {
      if (taskID != this.timerDuration?.taskID) {
         return;
      }
      let elapsedTime = this.timerDuration?.duration;
      if (elapsedTime == null || elapsedTime == 0) {
         return;
      }
      const now = new Date().getTime();
      elapsedTime = elapsedTime + (now - this.timerDuration?.timestamp) / 1000;
      timer.addTime(elapsedTime);
      this.resetDuration();
   }

   public resetDuration() {
      this.timerDuration = null;
   }
}

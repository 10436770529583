import type { PipeTransform } from "@angular/core";
import { Pipe } from "@angular/core";
import type { Aliases } from "@limblecmms/lim-ui";
import { capitalize } from "src/app/shared/pipes/capitalize.pipe";
/*
 * Takes an fa icon string and changes it to an icon Alias.
 *
 */
@Pipe({
   name: "iconAlias",
   standalone: true,
})
export class IconAlias implements PipeTransform {
   private iconStyle: string = "";
   private iconName: string = "";
   private readonly faStyles: string[] = ["solid", "regular", "brands"];

   public transform(icon: string): Aliases {
      const faClasses: string[] = icon.split(" ");
      this.setIconProps(faClasses);
      const alias: Aliases = this.createAlias();
      return alias;
   }

   private setIconProps(faClasses: string[]): void {
      for (const faClass of faClasses) {
         const core = this.extractCore(faClass);

         if (this.faStyles.includes(core)) {
            this.iconStyle = core;
            continue;
         }

         this.iconName = core;
      }
   }

   private extractCore(faClass: string): string {
      const core: string[] = faClass.split("-");
      if (core.shift() !== "fa") {
         throw new Error("Invalid fa icon class.");
      }

      return this.makeCamelCase(core);
   }

   private makeCamelCase(stringArr: string[]): string {
      let camelCase: string = stringArr.shift() ?? "";

      for (const string of stringArr) {
         camelCase += capitalize(string);
      }

      return camelCase;
   }

   private createAlias(): Aliases {
      const style = this.iconStyle;
      let name = this.iconName;

      if (style === "regular") {
         name += "Regular";
      }

      return name as Aliases;
   }
}

import { Pipe, type PipeTransform } from "@angular/core";

@Pipe({
   name: "timerFormat",
   standalone: true,
})
export class TimerFormatPipe implements PipeTransform {
   /**
    * Takes a number of seconds and converts into a string of the form
    * "{whole hours}:{remainder whole minutes}:{remainder seconds}"
    *
    * Example inputs => outputs:
    * - `0` => `"00:00:00"`
    * - `1` => `"00:00:01"`
    * - `60` => `"00:01:00"`
    * - `3600` => `"01:00:00"`
    * - `5000` => `"01:23:20"`
    */
   public transform(input: unknown): `${string}:${string}:${string}` {
      const secondsInput = Number(input);
      if (isNaN(secondsInput)) {
         return "00:00:00";
      }
      return this.format(this.getTimerParts(secondsInput));
   }

   private getTimerParts(inputSeconds: number): {
      hours: number;
      minutes: number;
      seconds: number;
   } {
      return {
         hours: Math.floor(inputSeconds / 3600),
         minutes: Math.floor((inputSeconds % 3600) / 60),
         seconds: inputSeconds % 60,
      };
   }

   private format({
      hours,
      minutes,
      seconds,
   }: {
      hours: number;
      minutes: number;
      seconds: number;
   }): `${string}:${string}:${string}` {
      const hoursString = hours.toString().padStart(2, "0");
      const minutesString = minutes.toString().padStart(2, "0");
      const secondsString = seconds.toString().padStart(2, "0");
      return `${hoursString}:${minutesString}:${secondsString}`;
   }
}

import { nonNullableExcept } from "src/app/shared/utils/validation.utils";
import { taskFileSchemaStrict } from "src/app/tasks/schemata/tasks/files/task-file.schema.strict";
import { z } from "zod";

export const taskFileSchema = z.object({
   fileID: z.number(),
   parentType: z.enum([
      "instructional-image",
      "instructional-document",
      "attachment-instruction-response-image",
      "attachment-instruction-response-document",
      "signature-instruction-response",
      "completion-image",
      "completion-document",
      "comment-image",
      "comment-document",
      "task-image",
   ]),
   parentID: z.number(),
   fileName: z.string(),
   arrayBuffer: z.instanceof(ArrayBuffer),
   mimeType: z.string(),
});

export const attachmentInstructionResponseSchema = nonNullableExcept(
   taskFileSchemaStrict,
   ["fileDescription", "migratedFeedback"],
);

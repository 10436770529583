import { z } from "zod";

//We added optional parameters due to recent changes in flannel that prune unused fields.
export const assetFieldValueSchemaStrict = z.object({
   valueID: z.number(),
   valueContent: z.string().optional(),
   valueLabel: z.string().optional(),
   valueSort: z.number(),
   assetID: z.number(),
   fieldID: z.number(),
   reportCostOfOwnership: z.number().optional(),
   viewableByTech: z.number().optional(),
   valueLastEdited: z.number(),
   valueDeleted: z.number().optional(),
});

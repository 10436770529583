@if (!uploadObject().viewOnly) {
   @if (isAndroidMenuNeeded()) {
      <android-file-upload-menu
         [isDocumentOptionIncluded]="uploadType() !== 'images'"
         (takePhoto)="uploadFromCamera()"
         (addImage)="uploadFromGallery()"
         (addDocument)="uploadFromFileBrowser()"
      >
         @switch (buttonType()) {
            @case ("secondary") {
               <lim-ui-secondary-button
                  (mouseover)="allowPaste()"
                  (mouseleave)="removePaste()"
                  class="hidePrint"
                  [ngClass]="uploading ? 'disabled' : ''"
                  [icon]="icon()"
                  [classNames]="'mobile-full-width'"
               >
                  {{ label() }}
               </lim-ui-secondary-button>
            }
            @case ("primary") {
               <lim-ui-primary-button
                  (mouseover)="allowPaste()"
                  (mouseleave)="removePaste()"
                  class="hidePrint"
                  [ngClass]="uploading ? 'disabled' : ''"
                  [icon]="icon()"
               >
                  {{ label() }}
               </lim-ui-primary-button>
            }
            @case ("table") {
               <lim-ui-table-button
                  (mouseover)="allowPaste()"
                  (mouseleave)="removePaste()"
                  class="hidePrint"
                  [ngClass]="uploading ? 'disabled' : ''"
                  [icon]="icon()"
               />
            }
         }
      </android-file-upload-menu>
   } @else {
      @switch (buttonType()) {
         @case ("secondary") {
            <lim-ui-secondary-button
               (mouseover)="allowPaste()"
               (mouseleave)="removePaste()"
               (click)="uploadByConfiguredType()"
               class="hidePrint"
               [ngClass]="uploading ? 'disabled' : ''"
               [icon]="icon()"
               [classNames]="'mobile-full-width'"
            >
               {{ label() }}
            </lim-ui-secondary-button>
         }
         @case ("primary") {
            <lim-ui-primary-button
               (mouseover)="allowPaste()"
               (mouseleave)="removePaste()"
               (click)="uploadByConfiguredType()"
               class="hidePrint"
               [ngClass]="uploading ? 'disabled' : ''"
               [icon]="icon()"
            >
               {{ label() }}
            </lim-ui-primary-button>
         }
         @case ("table") {
            <lim-ui-table-button
               (mouseover)="allowPaste()"
               (mouseleave)="removePaste()"
               (click)="uploadByConfiguredType()"
               class="hidePrint"
               [ngClass]="uploading ? 'disabled' : ''"
               [icon]="icon()"
            />
         }
      }
   }
}
@if (uploading && loadingIndicator()) {
   <div id="loadingViewer">
      <lim-ui-loading-animation
         class="loading-viewer-img"
         animationSize="extra-small"
         [ngClass]="{ uploadProgressing: uploading, notUploading: !uploading }"
      />
   </div>
}

import {
   TASK_FILE_DATA_LIMIT,
   TASK_FILE_LIMIT,
} from "src/app/lite/local-db/resources/collection/task/task-limits";

export class FileLimiter {
   private readonly fileLimit: number;
   private readonly dataLimit: number;
   private byteCount = 0;
   private fileCount = 0;

   public constructor({
      fileLimit = Infinity,
      dataLimit = Infinity,
   }: {
      fileLimit?: number;
      dataLimit?: number;
   }) {
      this.fileLimit = fileLimit;
      this.dataLimit = dataLimit;
   }

   public limitReached(): boolean {
      if (this.fileCount >= this.fileLimit) {
         console.warn(`Reached Lite file count limit of ${this.fileLimit}`);
         return true;
      }
      if (this.byteCount >= this.dataLimit) {
         console.warn(
            `Reached Lite file data limit of ~${this.dataLimit / 1_000_000} MB`,
         );
         return true;
      }
      return false;
   }

   public incrementFileCount(fileCount: number = 1): void {
      this.fileCount += fileCount;
   }

   public increaseDataUsage(byteCount: number): void {
      this.byteCount += byteCount;
   }

   public reset(): void {
      this.byteCount = 0;
      this.fileCount = 0;
   }
}

export const fileLimiter = new FileLimiter({
   fileLimit: TASK_FILE_LIMIT,
   dataLimit: TASK_FILE_DATA_LIMIT,
});

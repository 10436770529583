import { Injectable } from "@angular/core";
import type { Observable } from "rxjs";
import { CollectionStorageService } from "src/app/lite/local-db/resources/collection/collection.storage.service";
import type {
   ExtractDisableAutoIncrement,
   ExtractIndexes,
   ExtractPrimaryKey,
   ExtractSchema,
} from "src/app/lite/local-db/resources/collection/collection.storage.service.types";
import { instructionOptionResource } from "src/app/lite/local-db/resources/collection/task/instruction/instruction-option/instruction-option.resource";
import type { TaskInstructionOption } from "src/app/lite/local-db/resources/collection/task/instruction/instruction-option/instruction-option.types";

@Injectable({ providedIn: "root" })
export class InstructionOptionStorageService extends CollectionStorageService<
   ExtractSchema<typeof instructionOptionResource>,
   ExtractPrimaryKey<typeof instructionOptionResource>,
   ExtractDisableAutoIncrement<typeof instructionOptionResource>,
   ExtractIndexes<typeof instructionOptionResource>
> {
   public constructor() {
      super(instructionOptionResource);
   }

   public getOptionsForInstruction$(
      instructionID: number,
   ): Observable<Array<TaskInstructionOption>> {
      return this.read$((transaction) =>
         this.getAllFromIndex$("itemID", transaction, { indexValue: instructionID }),
      );
   }

   public async putInstructionOption(
      instructionOption: TaskInstructionOption,
   ): Promise<void> {
      await this.write(async (transaction) => this.put(instructionOption, transaction));
   }

   public async deleteInstructionOption(instructionOptionID: number): Promise<void> {
      await this.write(async (transaction) =>
         this.delete(instructionOptionID, transaction),
      );
   }
}

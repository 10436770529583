import { filter, map, mergeMap, of } from "rxjs";
import { fetchAndMapWithDependencies } from "src/app/lite/local-db/resources/collection/collection.resource.utils";
import { downloadFileForLitePrep } from "src/app/lite/local-db/resources/collection/task/file/download-file-for-lite-prep-operator";
import { buildPathPrefixSignature } from "src/app/lite/local-db/resources/collection/task/file/variants/signature-instruction-response/signature-instruction-response.utils";
import { instructionResource } from "src/app/lite/local-db/resources/collection/task/instruction/instruction.resource";
import { currentUserResource } from "src/app/lite/local-db/resources/properties/current-user/current-user.resource";
import { TaskInstructionTypeID } from "src/app/tasks/schemata/tasks/instructions/task-instruction.enum";

const signatureInstructionSet = new Set([
   TaskInstructionTypeID.CaptureSignature,
   TaskInstructionTypeID.RequestApproval,
]);

export const signatureInstructionResponseInit = fetchAndMapWithDependencies({
   from: instructionResource,
   with: [currentUserResource],
   to: (deps) =>
      deps.pipe(
         filter(([instruction]) => signatureInstructionSet.has(instruction.itemTypeID)),
         filter(
            ([instruction]) =>
               instruction.itemResponse !== null && instruction.itemResponse.length > 0,
         ),
         mergeMap(([instruction, currentUser]) =>
            of(instruction.itemResponse).pipe(
               filter(
                  (itemResponse): itemResponse is string =>
                     typeof itemResponse === "string",
               ),
               filter((fileName) => fileName.length > 1),
               map((fileName) => ({
                  ...instruction,
                  fileName,
                  customerID: currentUser.customerID,
               })),
            ),
         ),
         downloadFileForLitePrep((params) => ({
            ...params,
            parentType: "signature-instruction-response",
            parentID: params.itemID,
            fileDownloadPathPrefix: buildPathPrefixSignature(params.itemBatchID),
         })),
      ),
});

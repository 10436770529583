import { AssetFieldTypeID } from "src/app/assets/schemata/fields/types/asset-field-type.enum";
import { AssetFieldScopeType } from "src/app/assets/services/asset-field.types";
import { z } from "zod";

export const assetFieldSchemaStrict = z.object({
   fieldID: z.number(),
   fieldName: z.string(),
   fieldTypeID: z.nativeEnum(AssetFieldTypeID),
   locationID: z.number(),
   viewableByTechFieldDefault: z.number(),
   optionsJSON: z.string(),
   dateReminder1: z.number(),
   dateReminder2: z.number(),
   dateReminder3: z.number(),
   userID: z.number(),
   profileID: z.number(),
   displayOnTasks: z.number(),
   valueUnique: z.number(),
   isCustomDefault: z.number(),
   updateChildAssetFieldValue: z.enum(["replace", "increment", "ignore"]),
   lockedDefault: z.number(),
   scopeType: z.nativeEnum(AssetFieldScopeType),
});

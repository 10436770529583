import { assetFieldSchemaStrict } from "src/app/assets/schemata/fields/asset-field.schema.strict";
import { nonNullableExcept } from "src/app/shared/utils/validation.utils";

export const assetFieldSchema = nonNullableExcept(
   assetFieldSchemaStrict.pick({
      fieldID: true,
      fieldName: true,
      fieldTypeID: true,
      locationID: true,
      displayOnTasks: true,
   }),
   ["displayOnTasks"],
);

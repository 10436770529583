import { filter, from, map, mergeMap } from "rxjs";
import { hasImageExtension } from "src/app/files/file.util";
import { fetchAndMapWithDependencies } from "src/app/lite/local-db/resources/collection/collection.resource.utils";
import { downloadFileForLitePrep } from "src/app/lite/local-db/resources/collection/task/file/download-file-for-lite-prep-operator";
import { instructionResource } from "src/app/lite/local-db/resources/collection/task/instruction/instruction.resource";
import { currentUserResource } from "src/app/lite/local-db/resources/properties/current-user/current-user.resource";

export const instructionalFileInit = fetchAndMapWithDependencies({
   from: instructionResource,
   with: [currentUserResource],
   to: (deps) =>
      deps.pipe(
         mergeMap(([instruction, currentUser]) =>
            from(instruction.itemFileName?.split(";") ?? []).pipe(
               map((fileName) => ({
                  ...instruction,
                  fileName,
                  customerID: currentUser.customerID,
               })),
            ),
         ),
         filter((instructionFileParams) => instructionFileParams.fileName.length > 1),
         downloadFileForLitePrep((params) => ({
            ...params,
            parentType: hasImageExtension(params.fileName)
               ? "instructional-image"
               : "instructional-document",
            parentID: params.itemID,
            fileDownloadPathPrefix: `items/${params.itemBatchID}`,
         })),
      ),
});

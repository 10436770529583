import {
   EMPTY,
   catchError,
   concatMap,
   mergeMap,
   of,
   takeWhile,
   type Observable,
} from "rxjs";
import { fileLimiter } from "src/app/lite/local-db/resources/collection/task/file/file-limiter";
import type { TaskFileData } from "src/app/lite/local-db/resources/collection/task/file/file.types";
import { downloadAndFormatTaskFile } from "src/app/lite/local-db/resources/collection/task/file/file.utils";
import type { BuildTaskFileParams } from "src/app/lite/local-db/resources/collection/task/file/file.utils.params";

/**
 * A custom RxJs operator that downloads a file for Lite prep. It ignores
 * files that failed to download. It also tracks the number of executions
 * and the data volume downloaded. It will complete if the limit for either
 * metric is reached.
 */
export function downloadFileForLitePrep<T>(
   project: (params) => BuildTaskFileParams,
): (source: Observable<T>) => Observable<TaskFileData> {
   return (source: Observable<T>): Observable<TaskFileData> => {
      return source.pipe(
         concatMap((params) => {
            fileLimiter.incrementFileCount();
            return downloadAndFormatTaskFile(project(params)).pipe(
               catchError((error: Error) => of(error)),
            );
         }),
         takeWhile(() => !fileLimiter.limitReached(), true),
         mergeMap((value) => {
            if (value instanceof Error) {
               return EMPTY;
            }
            return of(value);
         }),
      );
   };
}

import {
   type ConnectedPosition,
   type FlexibleConnectedPositionStrategy,
   Overlay,
   type OverlayRef,
} from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import {
   Component,
   type ElementRef,
   ViewChild,
   inject,
   input,
   output,
   signal,
   computed,
   type Signal,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ScrollService } from "@limblecmms/lim-ui";
import { AndroidFileUploadMenuOverlayComponent } from "src/app/mobile/files/android-file-upload-menu/android-file-upload-menu-overlay/android-file-upload-menu-overlay.component";

@Component({
   selector: "android-file-upload-menu",
   templateUrl: "./android-file-upload-menu.component.html",
   imports: [],
})
export class AndroidFileUploadMenuComponent {
   private readonly scrollService = inject(ScrollService);
   private readonly overlay = inject(Overlay);
   private overlayRef?: OverlayRef;

   @ViewChild("trigger") private readonly triggerElement!: ElementRef<HTMLElement>;

   public readonly isDocumentOptionIncluded = input.required<boolean>();
   public readonly takePhoto = output();
   public readonly addImage = output();
   public readonly addDocument = output();

   private readonly placement = signal<"above" | "below">("above");
   private readonly position: Signal<ConnectedPosition> = computed(() => {
      if (this.placement() === "above") {
         return {
            originX: "start",
            originY: "top",
            overlayX: "start",
            overlayY: "bottom",
         };
      }
      return {
         originX: "start",
         originY: "bottom",
         overlayX: "start",
         overlayY: "top",
      };
   });
   private isMenuVisible = false;

   public constructor() {
      this.hideMenuOnComponentScroll();
   }

   protected handleTriggerClick(): void {
      this.updatePlacement();
      this.isMenuVisible = !this.isMenuVisible;
      if (this.isMenuVisible) {
         this.showMenu();
      } else {
         this.hideMenu();
      }
   }

   private showMenu(): void {
      const positionStrategy: FlexibleConnectedPositionStrategy = this.overlay
         .position()
         .flexibleConnectedTo(this.triggerElement)
         .withPositions([this.position()]);

      this.overlayRef = this.overlay.create({
         positionStrategy,
         hasBackdrop: true,
      });

      const menuPortal = new ComponentPortal(AndroidFileUploadMenuOverlayComponent);
      const componentRef = this.overlayRef.attach(menuPortal);

      componentRef.setInput("isDocumentOptionIncluded", this.isDocumentOptionIncluded());
      componentRef.setInput("placement", this.placement());

      componentRef.instance.takePhoto.subscribe(() => {
         this.takePhoto.emit();
         this.hideMenu();
      });

      componentRef.instance.addImage.subscribe(() => {
         this.addImage.emit();
         this.hideMenu();
      });

      componentRef.instance.addDocument.subscribe(() => {
         this.addDocument.emit();
         this.hideMenu();
      });

      this.overlayRef.backdropClick().subscribe(() => {
         this.hideMenu();
      });
   }

   private hideMenuOnComponentScroll(): void {
      this.scrollService.scroll$.pipe(takeUntilDestroyed()).subscribe(() => {
         this.hideMenu();
      });
   }

   private updatePlacement(): void {
      this.placement.set(this.isTriggerBelowMidpoint() ? "above" : "below");
   }

   private hideMenu(): void {
      this.overlayRef?.detach();
      this.isMenuVisible = false;
   }

   private isTriggerBelowMidpoint(): boolean {
      return this.getTriggerVerticalMidpoint() > this.getWindowVerticalMidpoint();
   }

   private getTriggerVerticalMidpoint(): number {
      const triggerRectangle = this.triggerElement.nativeElement.getBoundingClientRect();

      return triggerRectangle.y + triggerRectangle.height / 2;
   }

   private getWindowVerticalMidpoint(): number {
      return window.innerHeight / 2;
   }
}

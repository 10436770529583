import { assetFieldInitializer } from "src/app/lite/local-db/resources/collection/asset/field/asset-field.initializer";
import { assetFieldSchema } from "src/app/lite/local-db/resources/collection/asset/field/asset-field.schema";
import { CollectionResource } from "src/app/lite/local-db/resources/collection/collection.resource";

export const assetFieldResource = new CollectionResource({
   name: "assetFields",
   validation: assetFieldSchema,
   primaryKey: "fieldID",
   indexes: ["locationID"] as const,
   initialize: assetFieldInitializer,
});

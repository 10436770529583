import type { OnInit } from "@angular/core";
import { inject, Component, computed } from "@angular/core";
import {
   BasicModalHeaderComponent,
   IconButtonComponent,
   ModalBodyComponent,
   ModalComponent,
   ModalDirective,
   OutlinedButtonComponent,
   PanelComponent,
   PrimaryButtonComponent,
} from "@limblecmms/lim-ui";
import { ManageFiles } from "src/app/files/services/manageFiles";
import { ManageLang } from "src/app/languages/services/manageLang";
import { AlertService } from "src/app/shared/services/alert.service";
import { ParamsService } from "src/app/shared/services/params.service";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "taskCompletionSuccessful",
   templateUrl: "./taskCompletionSuccessful.modal.component.html",
   styleUrls: ["./taskCompletionSuccessful.modal.component.scss"],
   imports: [
      ModalComponent,
      ModalDirective,
      BasicModalHeaderComponent,
      ModalBodyComponent,
      PanelComponent,
      IconButtonComponent,
      PrimaryButtonComponent,
      OutlinedButtonComponent,
   ],
})
export class TaskCompletionSuccessful implements OnInit {
   public message;
   public gifName;
   public imageUrl;
   public resolve;
   public modalInstance;
   public userID;
   public customerID;

   private readonly paramsService = inject(ParamsService);
   private readonly manageFiles = inject(ManageFiles);
   private readonly alertService = inject(AlertService);
   private readonly manageUser = inject(ManageUser);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public ngOnInit() {
      const params = this.paramsService.params;
      if (params?.resolve) {
         this.resolve = params.resolve;
      }
      if (params?.modalInstance) {
         this.modalInstance = params.modalInstance;
      }

      this.message = this.resolve.message;
      this.gifName = this.resolve.gifName;
      this.imageUrl = this.resolve.imageUrl;
   }

   protected shareCompletionGif() {
      const gifUrl = `app.limblecmms.com/${this.manageFiles.mostRecentTaskGifUrl}`;

      const msg = `${this.lang().CopiedTheGifLinkToTheClipboard}:<br> <b>${gifUrl}</b>`;

      navigator.clipboard.writeText(gifUrl);
      this.alertService.addAlert(msg, "warning", 5000);

      if (!this.userID) {
         this.userID = this.manageUser.getCurrentUser().gUserID;
      }
      if (!this.customerID) {
         this.customerID = this.manageUser.getCurrentUser().userInfo.customerID;
      }

      const fileName = this.manageFiles.mostRecentTaskGifUrl?.substr(28);

      this.manageUser.logSharedCongratsMsg(fileName);
   }

   protected close() {
      if (this.modalInstance) {
         this.modalInstance.close(0);
      }
   }

   protected disableCongratsMsg() {
      this.manageUser.setUserShowCongratsMsg(this.userID, 0).then((answer) => {
         if (answer.data.success == true) {
            this.close();
            this.alertService.addAlert(this.lang().successMsg, "success", 1000);
         } else {
            this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
         }
      });
   }

   protected voteCongrats(vote) {
      const gif = this.gifName;
      this.manageUser.voteCongratsMsg(gif, vote).then((answer) => {
         if (answer.data.success == true) {
            this.alertService.addAlert(`${this.lang().ThankYou}!`, "success", 1500);
            this.close();
         }
      });
   }
}

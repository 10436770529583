import { filter, from, map, mergeMap, of } from "rxjs";
import { hasImageExtension } from "src/app/files/file.util";
import { fetchAndMapWithDependencies } from "src/app/lite/local-db/resources/collection/collection.resource.utils";
import { downloadFileForLitePrep } from "src/app/lite/local-db/resources/collection/task/file/download-file-for-lite-prep-operator";
import { buildPathPrefixCompletionFile } from "src/app/lite/local-db/resources/collection/task/file/variants/completion-file/completion-file.utils";
import { taskResource } from "src/app/lite/local-db/resources/collection/task/task.resource";
import type { Task } from "src/app/lite/local-db/resources/collection/task/task.types";
import { currentUserResource } from "src/app/lite/local-db/resources/properties/current-user/current-user.resource";

type TaskWithCompletionFiles = Omit<Task, "checklistEmailCNImages"> & {
   checklistEmailCNImages: NonNullable<Task["checklistEmailCNImages"]>;
};

export const completionFileInit = fetchAndMapWithDependencies({
   from: taskResource,
   with: [currentUserResource],
   to: (deps) =>
      deps.pipe(
         mergeMap(([taskWithOrWithoutCompletionFiles, currentUser]) =>
            of(taskWithOrWithoutCompletionFiles).pipe(
               filter(
                  (task): task is TaskWithCompletionFiles =>
                     task.checklistEmailCNImages !== null &&
                     task.checklistEmailCNImages !== "",
               ),
               mergeMap((task) =>
                  from(task.checklistEmailCNImages.split(";")).pipe(
                     map((fileName) => ({
                        ...task,
                        fileName,
                        customerID: currentUser.customerID,
                     })),
                  ),
               ),
            ),
         ),
         downloadFileForLitePrep((params) => ({
            ...params,
            parentType: hasImageExtension(params.fileName)
               ? "completion-image"
               : "completion-document",
            parentID: params.checklistID,
            fileDownloadPathPrefix: buildPathPrefixCompletionFile(params.checklistID),
         })),
      ),
});

import { filter, from, map, mergeMap } from "rxjs";
import { hasImageExtension } from "src/app/files/file.util";
import { fetchAndMapWithDependencies } from "src/app/lite/local-db/resources/collection/collection.resource.utils";
import { taskCommentResource as commentResource } from "src/app/lite/local-db/resources/collection/task/comment/comment.resource";
import { downloadFileForLitePrep } from "src/app/lite/local-db/resources/collection/task/file/download-file-for-lite-prep-operator";
import { currentUserResource } from "src/app/lite/local-db/resources/properties/current-user/current-user.resource";

export const commentFileInit = fetchAndMapWithDependencies({
   from: commentResource,
   with: [currentUserResource],
   to: (deps) =>
      deps.pipe(
         mergeMap(([comment, currentUser]) =>
            from(comment.noteFiles?.split("|") ?? []).pipe(
               map((fileName) => ({
                  ...comment,
                  fileName,
                  customerID: currentUser.customerID,
               })),
            ),
         ),
         filter((commentFileParams) => commentFileParams.fileName.length > 1),
         downloadFileForLitePrep((params) => ({
            ...params,
            parentType: hasImageExtension(params.fileName)
               ? "comment-image"
               : "comment-document",
            parentID: params.noteID,
            fileDownloadPathPrefix: `comments/${params.userID}/${params.checklistID}`,
         })),
      ),
});

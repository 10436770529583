import { filter, map } from "rxjs";
import { hasImageExtension } from "src/app/files/file.util";
import { fetchAndMapWithDependencies } from "src/app/lite/local-db/resources/collection/collection.resource.utils";
import { downloadFileForLitePrep } from "src/app/lite/local-db/resources/collection/task/file/download-file-for-lite-prep-operator";
import { attachmentInstructionResponseSchema } from "src/app/lite/local-db/resources/collection/task/file/file.schema";
import { buildPathPrefixAttachmentInstructionResponse } from "src/app/lite/local-db/resources/collection/task/file/variants/attachment-instruction-response/attachment-instruction-response.utils";
import { currentUserResource } from "src/app/lite/local-db/resources/properties/current-user/current-user.resource";

export const attachmentInstructionResponseInit = fetchAndMapWithDependencies({
   from: {
      url: "tasks/files",
      params: { taskStatus: "uncompleted" },
      validation: attachmentInstructionResponseSchema,
   },
   with: [currentUserResource],
   to: (deps) =>
      deps.pipe(
         map(([taskFileRow, currentUser]) => ({
            ...taskFileRow,
            customerID: currentUser.customerID,
         })),
         filter((instructionFileParams) => instructionFileParams.fileName.length > 1),
         downloadFileForLitePrep((params) => ({
            ...params,
            parentType: hasImageExtension(params.fileName)
               ? "attachment-instruction-response-image"
               : "attachment-instruction-response-document",
            parentID: params.itemID,
            fileDownloadPathPrefix: buildPathPrefixAttachmentInstructionResponse({
               taskID: params.checklistID,
               instructionID: params.itemID,
            }),
         })),
      ),
});

<ul id="menu" #menu [className]="placement()">
   <li (click)="takePhoto.emit()">
      <lim-ui-list-icon [icon]="'camera'" />
      <span>{{ i18n().t("TakePhoto") }}</span>
   </li>
   <li (click)="addImage.emit()">
      <lim-ui-list-icon [icon]="'imageRegular'" />
      <span>{{ i18n().t("UploadImage") }}</span>
   </li>
   @if (isDocumentOptionIncluded()) {
      <li (click)="addDocument.emit()">
         <lim-ui-list-icon [icon]="'file'" />
         <span>{{ i18n().t("UploadDocument") }}</span>
      </li>
   }
</ul>

import { formatCurrency } from "@angular/common";
import type { PipeTransform } from "@angular/core";
import { inject, Pipe } from "@angular/core";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageUser } from "src/app/users/services//manageUser";
/*
 * Takes a number and formats it to a currency using the user's currency symbol settings
 *    -replacement for angularJS "betterCurrency" filter
 * Takes a input number
 * Usage:
 *    number | betterCurrency
 * Example:
 *   part.partPrice | betterCurrency
 */
@Pipe({
   name: "betterCurrency",
   standalone: true,
})
/**
 * @deprecated use LocaleCurrencyPipe instead
 */
export class BetterCurrencyPipe implements PipeTransform {
   private readonly manageLang = inject(ManageLang);
   private readonly manageUser = inject(ManageUser);

   public transform(inputIn) {
      return betterCurrency(inputIn, this.manageLang, this.manageUser);
   }
}

export const betterCurrency = (inputIn, manageLang, manageUser: ManageUser) => {
   let input = inputIn;
   if (input !== undefined) {
      input = formatCurrency(
         input,
         manageLang.getLocaleID(),
         manageUser.getCurrentUser().currency.symbol,
         undefined,
         "1.2-2",
      );
   }
   return input;
};

import { filter } from "rxjs";
import { fetchAndMapWithDependencies } from "src/app/lite/local-db/resources/collection/collection.resource.utils";
import { downloadFileForLitePrep } from "src/app/lite/local-db/resources/collection/task/file/download-file-for-lite-prep-operator";
import { buildTaskImage } from "src/app/lite/local-db/resources/collection/task/file/variants/task-image/task-image.utils";
import { taskResource } from "src/app/lite/local-db/resources/collection/task/task.resource";
import { currentUserResource } from "src/app/lite/local-db/resources/properties/current-user/current-user.resource";
import { assert } from "src/app/shared/utils/assert.utils";

export const taskImageInit = fetchAndMapWithDependencies({
   from: taskResource,
   with: [currentUserResource],
   to: (deps) =>
      deps.pipe(
         filter(([task]) => task.taskImage !== null && task.taskImage.length > 1),
         downloadFileForLitePrep(([task, currentUser]) => {
            assert(task.taskImage !== null);
            return {
               customerID: currentUser.customerID,
               fileName: task.taskImage,
               parentType: "task-image",
               parentID: task.checklistID,
               fileDownloadPathPrefix: buildTaskImage(task.checklistID),
            };
         }),
      ),
});

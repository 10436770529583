<div class="timer-container" [ngClass]="{ 'timer-paused': !getRunningState() }">
   <div class="timer-content" [limUiTooltip]="lang()?.TaskTimerTooltip">
      @if (getRunningState()) {
         <div class="play-pause pause-background" (click)="pause({ emit: false })">
            <lim-ui-icon
               class="pause-icon"
               icon="pause"
               [iconSize]="isMobile ? 'large' : 'medium'"
            />
         </div>
      } @else {
         <div class="play-pause play-background" (click)="resume()">
            <lim-ui-icon
               class="play-icon"
               icon="play"
               [iconSize]="isMobile ? 'large' : 'medium'"
            />
         </div>
      }
      <div class="stop-timer" (click)="pause({ emit: true })">
         <lim-ui-icon
            icon="stop"
            [iconSize]="isMobile ? 'large' : 'medium'"
            iconColor="danger"
         />
      </div>
      <div class="clock-container">
         <div class="timer-clock">
            {{ time() | timerFormat }}
         </div>
         @if (estimatedTime() > 0) {
            <div class="timer-estimated-time">
               {{ lang()?.EstimatedAbbreviation }}:
               {{ estimatedTime() | timerFormat }}
            </div>
         }
      </div>
   </div>
   @if (!getRunningState()) {
      <div class="paused-message">
         <b class="font-normal">{{ lang()?.Paused }}</b> -
         {{ lang()?.ClickPlayToResume }}
      </div>
   }
</div>

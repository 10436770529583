import { z } from "zod";

export const taskFileSchemaStrict = z.object({
   fileID: z.number(),
   fileName: z.string(),
   fileDescription: z.string(),
   itemID: z.number(),
   checklistID: z.number(),
   migrated: z.number(),
   migratedFeedback: z.string(),
});

import { Injectable } from "@angular/core";
import type { Observable } from "rxjs";
import { assetFieldValueResource } from "src/app/lite/local-db/resources/collection/asset/field/value/asset-field-value.resource";
import type { AssetFieldValueUpdate } from "src/app/lite/local-db/resources/collection/asset/field/value/asset-field-value.storage.service.types";
import type { AssetFieldValue } from "src/app/lite/local-db/resources/collection/asset/field/value/asset-field-value.types";
import { CollectionStorageService } from "src/app/lite/local-db/resources/collection/collection.storage.service";
import type {
   ExtractDisableAutoIncrement,
   ExtractIndexes,
   ExtractPrimaryKey,
   ExtractSchema,
   Insertable,
} from "src/app/lite/local-db/resources/collection/collection.storage.service.types";

@Injectable({ providedIn: "root" })
export class AssetFieldValueStorageService extends CollectionStorageService<
   ExtractSchema<typeof assetFieldValueResource>,
   ExtractPrimaryKey<typeof assetFieldValueResource>,
   ExtractDisableAutoIncrement<typeof assetFieldValueResource>,
   ExtractIndexes<typeof assetFieldValueResource>
> {
   public constructor() {
      super(assetFieldValueResource);
   }

   public async updateAssetFieldValue(
      valueID: number,
      update: AssetFieldValueUpdate,
   ): Promise<AssetFieldValue | undefined> {
      return this.write(async (transaction) => this.set(valueID, update, transaction));
   }

   public getAssetFieldValues$(): Observable<Array<AssetFieldValue>> {
      return this.read$((transaction) => this.getAll$(transaction));
   }

   public getAssetFieldValue$(
      assetFieldValueID: number,
   ): Observable<AssetFieldValue | undefined> {
      return this.read$((transaction) => this.get$(assetFieldValueID, transaction));
   }

   public async putAssetFieldValue(
      assetFieldValue: InsertableAssetFieldValue,
   ): Promise<AssetFieldValue> {
      return this.write(async (transaction) => this.put(assetFieldValue, transaction));
   }

   public async deleteAssetFieldValue(assetFieldValueID: number): Promise<void> {
      return this.write(async (transaction) =>
         this.delete(assetFieldValueID, transaction),
      );
   }
}

type InsertableAssetFieldValue = Insertable<typeof assetFieldValueResource>;

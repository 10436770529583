import { CollectionResource } from "src/app/lite/local-db/resources/collection/collection.resource";
import { taskFileSchema } from "src/app/lite/local-db/resources/collection/task/file/file.schema";
import { attachmentInstructionResponseInit } from "src/app/lite/local-db/resources/collection/task/file/variants/attachment-instruction-response/attachment-instruction-response.initializer";
import { commentFileInit } from "src/app/lite/local-db/resources/collection/task/file/variants/comment-file/comment-file.initializer";
import { completionFileInit } from "src/app/lite/local-db/resources/collection/task/file/variants/completion-file/completion-file.initializer";
import { instructionalFileInit } from "src/app/lite/local-db/resources/collection/task/file/variants/instructional-file/instructional-file.initializer";
import { signatureInstructionResponseInit } from "src/app/lite/local-db/resources/collection/task/file/variants/signature-instruction-response/signature-instruction-response.initializer";
import { taskImageInit } from "src/app/lite/local-db/resources/collection/task/file/variants/task-image/task-image.initializer";

export const taskFileResource = new CollectionResource({
   name: "taskFiles",
   validation: taskFileSchema,
   primaryKey: "fileID",
   indexes: [["parentType", "parentID"]] as const,
   initialize: [
      attachmentInstructionResponseInit,
      commentFileInit,
      instructionalFileInit,
      signatureInstructionResponseInit,
      completionFileInit,
      taskImageInit,
   ],
});

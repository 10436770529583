<lim-ui-dropdown placement="bottom-start" #dropdown secondaryIcon="angleDown">
   <div
      class="table-input-field-editable table-field-text table-input-field-dropdown time-of-day-display"
      [ngClass]="{
         'dropdown-open': dropdown.showMenu,
         'time-of-day-no-margin': timeOfDayNoMargin,
         'time-of-day-unset': !timeOfDay && optional,
      }"
      button
      id="timeOfDayPickerCategory"
   >
      @if (timeOfDay) {
         <span> {{ hour }}:{{ minute }} {{ ampm }} </span>
      } @else {
         {{ lang().AddTime }}
      }

      @if (timeOfDay) {
         <lim-ui-minimal-icon-button
            [limUiTooltip]="lang()?.DontSetASpecificTimeOfDay"
            (click)="resetAnyTime(); $event.stopPropagation()"
            icon="xmarkLarge"
            iconColor="danger"
            [superMinimal]="true"
         />
      }
   </div>
   <div menu aria-labelledby="timeOfDayPickerCategory">
      @for (time of times; track time) {
         <lim-ui-dropdown-text-item
            displayString="{{ time.hour }}:{{ time.minute }} {{ time.ampm }}"
            outputString="{{ time.hour }}:{{ time.minute }} {{ time.ampm }}"
            (itemSelectedEvent)="setTimeData(time)"
         />
      }

      <lim-ui-dropdown-divider />
      <lim-ui-dropdown-clear-filter-item
         (click)="resetAnyTime(); $event.stopPropagation()"
      >
         {{ lang()?.DontSetASpecificTimeOfDay ?? "" }}
      </lim-ui-dropdown-clear-filter-item>
   </div>
</lim-ui-dropdown>

import { z } from "zod";

export const taskInstructionOptionSchemaStrict = z.object({
   itemOptionID: z.number(),
   itemOptionOrder: z.number(),
   itemOptionCount: z.number(),
   itemOptionText: z.string(),
   itemID: z.number(),
   itemOptionDescription: z.string(),
   itemOptionCollapsed: z.number(),
   customTagToAdd: z.string(),
   customTagToAddEvent: z.number(),
   customTagToRemove: z.string(),
   customTagToRemoveEvent: z.number(),
});

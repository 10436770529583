import { Injectable } from "@angular/core";

interface TimeControlValue {
   hour: string;
   minute: string;
   ampm: string;
}

@Injectable({
   providedIn: "root",
})
export class DateUtilsService {
   public MS_IN_DAY = 1000 * 3600 * 24;

   public convertToSeconds(minutes: number, hours: number = 0): number {
      const SIXTY_MINUTES = 60;
      const SIXTY_SECONDS = 60;
      return hours * SIXTY_MINUTES * SIXTY_SECONDS + minutes * SIXTY_SECONDS;
   }

   public unixTimestampToDate(unixTimestamp: number): Date | null {
      if (unixTimestamp < 0) {
         console.error("Invalid input: Timestamp cannot be negative.");
         return null;
      }
      return new Date(unixTimestamp * 1000);
   }

   public dateToUnixTimestamp(date: Date): number {
      return Math.floor(date.getTime() / 1000);
   }

   public isWithin30Days(timestamp: Date | string | number | null): boolean {
      if (!timestamp) return false;
      const THIRTY_DAYS = 30;
      const currentDate = new Date();
      const otherDate = new Date(timestamp);
      const timeDiff = currentDate.getTime() - otherDate.getTime();
      const daysDiff = timeDiff / this.MS_IN_DAY;
      return daysDiff <= THIRTY_DAYS;
   }

   public convertAMPMHours(timeData: TimeControlValue): number {
      let hours = parseInt(timeData.hour, 10);
      if (timeData.ampm === "AM" && timeData.hour === "12") {
         return 0;
      }
      if (timeData.ampm === "PM") {
         if (timeData.hour === "12") {
            return 12;
         }
         hours += 12;
      }
      return hours;
   }

   /**
    * Calculates the difference in days between two timestamps.
    *
    * @param startTimestamp - The start timestamp in seconds (e.g., ordered date).
    * @param endTimestamp - The end timestamp in seconds (e.g., received date).
    * @returns The difference in days, or 0 if either timestamp is invalid.
    */
   public calculateDaysBetweenDates(
      startTimestamp: number,
      endTimestamp: number,
   ): number {
      if (startTimestamp <= 0 || endTimestamp <= 0) {
         return 0; // Return 0 if either timestamp is invalid
      }

      const startDate = new Date(startTimestamp * 1000); // Convert seconds to milliseconds
      const endDate = new Date(endTimestamp * 1000); // Convert seconds to milliseconds

      const differenceInMilliseconds = endDate.getTime() - startDate.getTime();
      const differenceInDays = Math.max(
         0,
         Math.floor(differenceInMilliseconds / (1000 * 3600 * 24)),
      ); // Convert to days, ensure non-negative
      return differenceInDays;
   }
}

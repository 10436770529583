import type { WorkerConfig } from "src/app/lite/local-db/offline-prep.worker.types";
import { currentUserSchema } from "src/app/lite/local-db/resources/properties/current-user/current-user.schema";
import { PropertiesResource } from "src/app/lite/local-db/resources/properties/properties.resource";

export const currentUserResource = new PropertiesResource({
   name: "currentUser",
   initialize: {
      validation: currentUserSchema,
      sendRequestParams: (workerConfig: WorkerConfig) => {
         if (workerConfig.launchFlags.useMiniFlannelForUserSelf) {
            return {
               // We have to use a function here; the legacy behavior is to prepend
               // the Flannel prefix to everything.
               url: `${workerConfig.env.servicesUrl}users/self`,
            };
         }
         return {
            url: "users/self",
         };
      },
   },
});

import { formatNumber } from "@angular/common";
import type { PipeTransform } from "@angular/core";
import { inject, Pipe } from "@angular/core";
import { ManageLang } from "src/app/languages/services/manageLang";
/*
 * Takes a number and formats it to the options (a string) provided
      -the options are in the format '(min digits before decimal).(min digits after decimal)-(max digits after decimal)'
 * Takes a input number
 * Usage:
 *    number | betterNumber: 'options'
 * Example:
 *   part.partPrice | betterNumber: '1.2-2
 */
@Pipe({
   name: "betterNumber",
   standalone: true,
})
export class BetterDecimalPipe implements PipeTransform {
   private readonly manageLang = inject(ManageLang);

   public transform(inputIn, format) {
      let input = inputIn;
      if (input !== undefined) {
         input = formatNumber(input, this.manageLang.getLocaleID(), format);
      }
      return input;
   }
}

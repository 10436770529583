import { assetFieldValueSchemaStrict } from "src/app/assets/schemata/fields/values/asset-field-value.schema.strict";
import { nonNullableExcept } from "src/app/shared/utils/validation.utils";

export const assetFieldValueSchema = nonNullableExcept(
   assetFieldValueSchemaStrict.pick({
      valueID: true,
      assetID: true,
      fieldID: true,
      valueContent: true,
   }),
   ["valueContent"],
);

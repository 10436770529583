import { nonNullableExcept } from "src/app/shared/utils/validation.utils";
import { taskInstructionOptionSchemaStrict } from "src/app/tasks/schemata/tasks/instructions/options/task-instruction-option.schema.strict";

export const instructionOptionSchema = nonNullableExcept(
   taskInstructionOptionSchemaStrict.pick({
      itemOptionID: true,
      itemOptionOrder: true,
      itemOptionCount: true,
      itemOptionText: true,
      itemID: true,
      customTagToAdd: true,
      customTagToAddEvent: true,
      customTagToRemove: true,
      customTagToRemoveEvent: true,
   }),
   ["customTagToAdd", "customTagToRemove"],
);

import type { TimerState } from "src/app/tasks/timer/timer.types";

/**
 * Measures real elapsed time, even if the application is not getting CPU time.
 * Measures time in seconds and persists state to localStorage.
 */
export class Timer {
   private checkpoint = 0;
   private startTime: number | null = null;
   private readonly taskID: number;
   private readonly STORAGE_KEY = "current-task-timer";

   public constructor(taskID: number) {
      this.taskID = taskID;
      this.loadState();
   }

   /** Returns a new Timer that immediately starts */
   public static start(taskID: number): Timer {
      const timer = new Timer(taskID);
      timer.start();
      return timer;
   }

   /** Start tracking time */
   public start(): void {
      if (this.startTime === null) {
         this.startTime = Date.now() / 1000;
         this.saveState();
      }
   }

   /** Returns the recorded elapsed time in seconds */
   public elapsedTime(): number {
      if (this.startTime === null) {
         return this.checkpoint;
      }
      const now = Date.now() / 1000;
      return Math.round(this.checkpoint + now - this.startTime);
   }

   /**
    * Sets the recorded elapsed time. Useful for making manual adjustments
    * to the timer.
    */
   public setElapsedTime(time: number): void {
      this.checkpoint = time;
      this.saveState();
   }

   /**
    * Causes the timer to cease tracking elapsed time. This does not affect
    * the recorded elapsed time up to this point. The timer can continue
    * tracking elapsed time by calling start() again.
    */
   public stop(): void {
      this.checkpoint = this.elapsedTime();
      this.startTime = null;
      this.saveState();
   }

   /** Returns true if the timer is currently tracking time */
   public isRunning(): boolean {
      return this.startTime !== null;
   }

   /** Resets the timer to its initial state: stopped, with zero time elapsed */
   public reset(): void {
      this.stop();
      this.checkpoint = 0;
      this.saveState();
   }

   /** Returns the number of whole hours elapsed */
   public hours(): number {
      return Math.floor(this.elapsedTime() / 3600);
   }

   /** Returns the remainder whole number of minutes elapsed */
   public minutes(): number {
      return Math.floor(this.elapsedTime() / 60) % 60;
   }

   /** Returns the remainder number of seconds elapsed */
   public seconds(): number {
      return this.elapsedTime() % 60;
   }

   private saveState(): void {
      const state: TimerState = {
         checkpoint: this.checkpoint,
         startTime: this.startTime,
         taskID: this.taskID,
      };
      localStorage.setItem(this.STORAGE_KEY, JSON.stringify(state));
   }

   private loadState(): void {
      // TODO: CASE-4993 - we need to revist this solution. Disabling this first fix for now.
      // const savedState = localStorage.getItem(this.STORAGE_KEY);
      // if (!savedState) return;
      // try {
      //    const state = JSON.parse(savedState) as TimerState;
      //    // If the timer is for a different task, the state
      //    // in localStorage will be overwritten by the new timer.
      //    if (state.taskID === this.taskID) {
      //       this.checkpoint = state.checkpoint;
      //       this.startTime = state.startTime;
      //    }
      // } catch (error) {
      //    console.error("Error parsing timer state", error);
      // }
   }
}
